import React from "react";
import { ReactComponent as ErrorStatus } from "src/assets/images/ErrorStatus.svg";
import { ReactComponent as WrongStatus } from "src/assets/images/Wrong.svg";
import { helperService } from "src/services/helperService";
import OrderTotal from "src/containers/merchant-confirm-payment/order-total";
import Styles from "src/containers/merchant-confirm-payment/styles.module.css";
const ErrorMessage = ({
  wrongPage = false,
  history,
  setFailureError,
  price,
}) => {
  return (
    <div
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className={Styles["category-heading"]} style={{ color: "#F13939" }}>
        Payment failed
      </div>
      <OrderTotal price={price} showConvienience={true} error={true} />
      <button
        className="nextBtn"
        style={{ marginTop: "20%" }}
        onClick={() => {
          setFailureError();
          history.push({ pathname: "/payment" });
          window.location.reload();
        }}
      >
        {" "}
        <p className="nextBtnTxt">Try again</p>{" "}
      </button>
    </div>
  );
};

export default ErrorMessage;
