import './styles.css'
import React from 'react';
import _ from 'lodash'
import { Images } from 'src/assets/images';
import { RxChevronDown } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import AuthActions from "src/stores/auth/actions"
import useDetectKeyboardOpen from 'src/hooks/useDetectKeyboardOpen';
import { withRouter } from 'react-router';
import { useEffect } from 'react';
// import WebviewWithUrlChange from 'src/components/common/WebviewWithUrlChange';

const Login = ({ history }) => {
  const [mobileNumber, setMobileNumber] = React.useState('');

  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.auth.storeData)

  useEffect(() => { dispatch(AuthActions.fetchStoreData()) }, [dispatch])

  const logoSrc = storeData.profilePictureUrl || Images.Logo;
  const flagSrc = Images.Flag;
  const businessName = storeData?.brand?.name;
  const businessPlace = storeData?.name;
  const isKeyboardOpen = useDetectKeyboardOpen()

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleSubmit = () => {
    const loginPayload = {
      appType: 'PERSONAL',
      phoneNumber: {
        countryCode: (mobileNumber.replace(/\D/g, '').toString() == '9971748773' || mobileNumber.replace(/\D/g, '').toString() == '9871348006') ? `91`: `971`,
        subscriberNumber: mobileNumber.replace(/\D/g, ''),
      },
      deviceOS: _.upperCase('WEB'),
      guid: 'testGuid',
    }
    setTimeout(() => {
      dispatch(
        AuthActions.initiateLogin({ loginPayload, history })
      )
    }, 100)
    // history.push({
    //   pathname: '/otp',
    //   state: { mobileNumber: loginPayload.phoneNumber}
    // })
  }

  const renderBottomSection = () => {
    return (
      <div className={!isKeyboardOpen ? 'bottom-div bottom-div-absolute' : 'bottom-div bottom-div-absolute1'}>
        <div>
          <p className='tnc'>By continuing you agree to our<br /><a>terms of use</a> and <a>privacy policy.</a></p>
        </div>
        <div>
          <button id='login__btn' className='nextBtn' onClick={handleSubmit} disabled={!mobileNumber} onMouseDown={() =>
            document.getElementById("login__btn").classList.add('touched')
          }> <p className='nextBtnTxt'>Next</p> </button>
        </div>

      </div>
    )
  }

  // if(true){
  //   // <WebviewWithUrlChange redirectionUrl={'https://www.google.com'} />
  //   return (<iframe id="iFrameExample"
  //       title="iFrame Example"
  //       src="https://www.google.com"
  //       width={'100%'}
  //       height={'100%'}
  //       >
  //   </iframe>)
  // }

  return (
    <div className='main'>
      <div className='login-container'>
        <img src={logoSrc} alt="Logo" className='logo' />
        <p className='name'>{businessName}</p>
        <p className='business'>{businessPlace}</p>
        <p className='heading'>Please enter mobile number</p>
        <div className='number-input-container'>
          <div className='number-input-left-container'>
            <img src={flagSrc} alt="Logo" className='flag' />
            <p className='countryCode'>+971</p>
            <RxChevronDown className='icon' />
          </div>
          <div className='number-input-right-container'>
            <input
              className='input-div'
              placeholder=""
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              autoFocus={true}
              maxLength={10}
              type='text'
              pattern="\d*"
            />
          </div>
        </div>
      </div>
      {renderBottomSection()}
    </div>
  );
};

Login.propTypes = {
  // logoSrc: PropTypes.string.isRequired,
  // businessName: PropTypes.string.isRequired,
  // businessPlace: PropTypes.string.isRequired,
};

export default withRouter(Login);
