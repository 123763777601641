import React from "react";
import { Images } from "src/assets/images";
import { AuthService } from "src/services/api/authService";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import ErrorMessage from "src/components/ErrorMessage";

const StoreBooking = ({ history, location }) => {
  const { categoryId, sessionId, name, description, duration } =
    location?.state || {};

  const [stylistData, setStoreStylistData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showServerError, setShowServerError] = useState(false)

  const handleGetAllStylistData = useCallback(async () => {
    setLoading(true);
    const response = await AuthService.stylistList({
      categoryId,
      sessionId,
      page: 1,
    });
    if (response?.success) {
      setStoreStylistData(response.data);
    } else {
      setShowServerError(true)
    }
    setLoading(false);
  }, [categoryId, sessionId]);

  useEffect(() => {
    handleGetAllStylistData();
  }, [handleGetAllStylistData]);

  const storeData = useSelector((state) => state.auth.storeData);
  const businessName = storeData?.brand?.name;
  const businessPlace = storeData?.name;
  const logoSrc = storeData.profilePictureUrl || Images.Logo;

  const renderHeader = () => {
    return (
      <div className="header-container">
        <img
          src={
            storeData?.businessStoreCardDetails?.heroBanner || Images.StoreBG
          }
          alt="store bg"
          className="header-img"
        />
        <div className="header-logo-div">
          <img src={logoSrc} alt="store logo" className="header-logo" />
          <div className="name">{businessName}</div>
          <div className="business">{businessPlace}</div>
        </div>
      </div>
    );
  };

  const onBookingDateClick = (data) => {
    history.push({
      pathname: "/booking-slots",
      state: {
        data,
        sessionData: { categoryId, sessionId, name, description, duration },
      },
    });
  };

  const renderItem = ({
    personalInfo: { id, first_name, last_name, price } = {},
    jobInfo: { job_name } = {},
    profilePic = "",
  }) => {
    return (
      <div
        className={"stylelist-wrapper"}
        key={id}
        onClick={() => {
          onBookingDateClick({
            personalInfo: { id, first_name, last_name, price },
            jobInfo: { job_name },
            profilePic,
          });
        }}
        onMouseDown={() => document.getElementById(id).classList.add("touched")}
      >
        <div id={id} key={id} className="stylelist-parent-container">
          <div className="stylist-container">
            <div className="stylist-left-container">
              {profilePic.length > 0 ? (
                <img src={profilePic} className="stylist-img" />
              ) : (
                <div className="stylist-img-bg">
                  <div className="stylist-img-bg-text">
                    {first_name[0]}
                    {last_name[0]}
                  </div>
                </div>
              )}
              <div className="stylist-details">
                <div className="stylist-name">
                  {first_name} {last_name}
                </div>
                <div>{job_name}</div>
              </div>
            </div>
            <div className="stylist-amount">AED {price}</div>
          </div>
        </div>
        <div className="separator"></div>
      </div>
    );
  };

  if(showServerError){
    return <ErrorMessage/>
  }

  return (
    <div className="store-cat-container">
      {renderHeader()}
      <div className="category-heading">Select your stylist</div>
      <div className="booking-heading">
        <div className="booking-heading-text">{name}</div>
        <div className="booking-heading-text">{duration} min</div>
      </div>
      <div className="booking-subheading">{description}</div>
      <div className="separator width-90"></div>
      {stylistData.map((item, index) => {
        return renderItem(item, index);
      })}
      {loading && (
        <div>
          <div style={{ marginTop: "12px" }}>
            <ContentLoader viewBox="-20 0 380 70" foregroundColor={"#999"}>
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="5" ry="5" width="60" height="60" />
              <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
              <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
          </div>
          <div style={{ marginTop: "12px" }}>
            <ContentLoader viewBox="-20 0 380 70" foregroundColor={"#999"}>
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="5" ry="5" width="60" height="60" />
              <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
              <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
          </div>
          <div style={{ marginTop: "12px" }}>
            <ContentLoader viewBox="-20 0 380 70" foregroundColor={"#999"}>
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="5" ry="5" width="60" height="60" />
              <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
              <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
          </div>
          <div style={{ marginTop: "12px" }}>
            <ContentLoader viewBox="-20 0 380 70" foregroundColor={"#999"}>
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="5" ry="5" width="60" height="60" />
              <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
              <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
          </div>
        </div>
      )}
    </div>
  );
};
export default StoreBooking;
