import React from "react";
import "./styles.css";
// import { ReactComponent as AppleLogo } from "src/assets/images/apple.svg"
import { ReactComponent as BalanceLogo } from "src/assets/images/balance-logo.svg";
import { ReactComponent as AmexLogo } from "src/assets/images/amex.svg";
import { ReactComponent as MasterCardLogo } from "src/assets/images/mastercard.svg";
import { ReactComponent as VisaLogo } from "src/assets/images/visa.svg";
import { ReactComponent as MaestroLogo } from "src/assets/images/maestro.svg";
import { ReactComponent as ErrorIcon } from "src/assets/images/errorIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import CheckoutActions from "src/stores/checkout/actions";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import {
  CHECKOUT_PUBLIC_KEY,
  CHECKOUT_REDIRECT_URL,
  helperService,
} from "src/services/helperService";
import { useEffect } from "react";
import AuthActions from "src/stores/auth/actions";
import { useState } from "react";
import Loader from "src/components/loader";
import ContentLoader from "react-content-loader";
import ApplePayButton from "src/components/common/ApplePayButton";
import axios from "axios";
import apiConstants from "src/config/env";

// import ApplePayButton from "src/components/common/ApplePayButton";

const PaymentOptions = ({
  history,
  price,
  sidToken,
  invoiceNumber,
  paymentLink,
}) => {
  const [applePaySession, setApplePaySession] = useState(null);
  console.log(
    { id: invoiceNumber, history, amount: price.billAmount },
    "confirm options"
  );

  // const { setPolling } = usePollingPayByBalance({
  //   id: invoiceNumber,
  //   history,
  //   amount: price.billAmount,
  // });
  const dispatch = useDispatch();
  const storeData = helperService.getItem("storeDetails");
  const userInfo = useSelector((state) => state.auth.userInfo);
  console.log("userInfo", userInfo, storeData);
  useEffect(() => {
    dispatch(AuthActions.fetchStoreData());
  }, [dispatch]);
  const [showLoader, setShowLoader] = useState(false);
  const [enablePayBtn, setEnablePayBtn] = useState(false);
  const [showCardError, setShowCardError] = useState(false);
  const [showExpError, setShowExpError] = useState(false);
  const [showCvvError, setShowCvvdError] = useState(false);
  const [paymentFailureError, setPaymentFailureError] = useState(false);
  console.log(storeData);
  useEffect(() => {
    console.log("price", price, sidToken);
    if (helperService.getItem("partnerFlagForProcess") != 1) {
      return;
    }
    if (Object.keys(storeData).length > 0 && sidToken) {
      localStorage.removeItem("partnerFlagForProcess");
      const requestPayload = {
        // businessAccountId: storeData?.account?.id,
        // amount: price.billAmount,
        // discountedAmount: price.discountedAmount,
        // convenienceAmount: price.convenienceFee,
        // taxAmount: price.taxAmount,
        // description: invoiceNumber,
        history: history,
        callBack: setShowLoader,

        destination: {
          userId: "00f6f03c-6ef5-407c-b06d-334db080b6f4", // buisness id
          accountType: "MERCHANT",
        },
        description: invoiceNumber,
        invoice: {
          amount: price.billAmount,
          fee: {
            processingFee: 0,
            // discountedAmount: price.discountedAmount,
            convenienceAmount: price.convenienceFee,
            // taxAmount: price.taxAmount,
          },
          description: "Test",
        },
        token: sidToken,
        transferType: "GUEST_PAYMENT",
        paymentChannel: "Y12BOOKING",
      };
      dispatch(CheckoutActions.processPayment(requestPayload));
    }
  }, [storeData, sidToken]);

  const onConfirmPayment = () => {
    setShowLoader(true);
    Frames.submitCard();
  };

  const verifyPayment = (token, type) => {
    let requestPayload = {
      token: token,
      transferType: type.toUpperCase(),
      amount: price.billAmount,
      referenceId: token,
      wallet: null,
      successUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_SUCCESS_URL,
      failureUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_FAILURE_URL,
      _3dsEnabled: type === "APPLE_PAY" ? false : true,
      setPaymentFailureError: setPaymentFailureError,
      showLoader: setShowLoader,
      paymentChannel: "Y12BOOKING",
      callbackUrl: `${apiConstants.BASE_URL}/orm/payment/invoice/${invoiceNumber}/amount/${price?.listPrice}/store/${storeData?.id}`,
    };
    console.log("onConfirmPayment ==> ", requestPayload);
    localStorage.setItem("partnerFlagForProcess", 1);
    dispatch(CheckoutActions.verifyAndPayment(requestPayload));
    document.getElementById("pay__button").classList.remove("touched");
  };

  const appleProcessPayment = (sidToken, successCallBack) => {
    console.log(
      "appleProcessPayment",
      typeof successCallBack === "function",
      successCallBack
    );
    const requestPayload = {
      destination: {
        userId: "00f6f03c-6ef5-407c-b06d-334db080b6f4", // buisness id
        accountType: "MERCHANT",
      },
      description: invoiceNumber,
      invoice: {
        amount: price.billAmount,
        fee: {
          processingFee: 0,
          // discountedAmount: price.discountedAmount,
          convenienceAmount: price.convenienceFee,
          // taxAmount: price.taxAmount,
        },
        description: "Test",
      },
      token: sidToken,
      transferType: "GUEST_PAYMENT",
      paymentChannel: "Y12BOOKING",
      history: history,
      callBack: (bool) => {
        setShowLoader(false);
        successCallBack(bool);
      },
    };
    dispatch(CheckoutActions.processPayment(requestPayload));
  };

  const applePayVerifyPayment = async (token, callBack) => {
    // const {version, data, signature,header} = token.paymentData
    // const response: IAddCardResponse = yield call(CheckoutService.getApplePayToken, payload)
    let cardPayload = {
      type: "applepay",
      money: price.billAmount,
      note: "apple pay",
      token_data: token.paymentData,
    };
    const response = await axios.post(
      `${apiConstants.CHECKOUT_URL}/tokens`,
      cardPayload,
      {
        headers: { Authorization: CHECKOUT_PUBLIC_KEY },
      }
    );
    console.log("applePayVerifyPayment", response.data, response?.data?.token);

    if (response?.data?.token) {
      console.log("coming here 123");
      console.log("coming here 345", typeof callBack === "function");
      let requestPayload = {
        token: response.data.token,
        transferType: "APPLE_PAY",
        amount: price.billAmount,
        referenceId: response.data.token,
        wallet: null,
        successUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_SUCCESS_URL,
        failureUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_FAILURE_URL,
        _3dsEnabled: false,
        paymentChannel: "Y12BOOKING",
        callbackUrl: `${apiConstants.BASE_URL}/orm/payment/invoice/${invoiceNumber}/amount/${price?.listPrice}/store/${storeData?.id}`,

        // setPaymentFailureError: setPaymentFailureError,
        showLoader: setShowLoader,
        applePayCallBack: appleProcessPayment,
        successCallBack: callBack,
      };
      console.log("onConfirmPayment ==> ", requestPayload);
      dispatch(AuthActions.OpenBookingLoadingModal(""));
      dispatch(CheckoutActions.verifyAndPayment(requestPayload));
    }

    document.getElementById("pay__button").classList.remove("touched");
  };

  const handleShowErrorMsg = (e) => {
    switch (e.element) {
      case "card-number":
        if (!e.isValid) {
          setShowCardError(true);
        } else {
          setShowCardError(false);
        }
        setPaymentFailureError(false);
        break;
      case "expiry-date":
        if (!e.isValid) {
          setShowExpError(true);
        } else {
          setShowExpError(false);
        }
        setPaymentFailureError(false);
        break;
      case "cvv":
        if (!e.isValid) {
          setShowCvvdError(true);
        } else {
          setShowCvvdError(false);
        }
        setPaymentFailureError(false);
        break;
      default:
        break;
    }
  };

  const renderCardDetails = () => {
    return (
      <div className="card-details-container">
        <div className="card-image-container">
          <VisaLogo style={{ marginRight: 20 }} />
          <MasterCardLogo style={{ marginRight: 20 }} />
          <MaestroLogo style={{ marginRight: 20 }} />
          <AmexLogo style={{ marginRight: 20 }} />
        </div>
        <Frames
          config={{
            publicKey: CHECKOUT_PUBLIC_KEY,
            localization: {
              cardNumberPlaceholder: "Card number",
              expiryMonthPlaceholder: "MM",
              expiryYearPlaceholder: "YY",
              cvvPlaceholder: "CVV",
            },
            style: {
              base: {
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: "20px",
                color: "#848C98",
              },
            },
          }}
          cardTokenized={(e) => {
            console.log("cardTokenized", e);
            verifyPayment(e.token, e.type);
          }}
          cardValidationChanged={(e) => {
            console.log("cardValidationChanged", e);
            if (e.isValid) {
              setEnablePayBtn(true);
            } else {
              setEnablePayBtn(false);
            }
          }}
          frameValidationChanged={(e) => {
            console.log("frameValidationChanged", e);
            handleShowErrorMsg(e);
          }}
          cardTokenizationFailed={(e) => {
            console.log("cardTokenizationFailed", e);
          }}
          paymentMethodChanged={(e) => {
            console.log("paymentMethodChanged", e);
          }}
        >
          <CardNumber
            className={
              showCardError ? "card-number-error-input" : "card-number-input"
            }
          />
          <div className="card-cvv-container">
            <div className="left-container">
              <span className="expiry-text">Exp</span>
              <div>
                <ExpiryDate
                  className={showExpError ? "card-expiry-error" : "card-expiry"}
                />
              </div>
            </div>
            <div>
              <Cvv className={showCvvError ? "card-cvv-error" : "card-cvv"} />
            </div>
          </div>
          <div className="invalid-container">
            {(paymentFailureError ||
              showCardError ||
              showExpError ||
              showCvvError) && <ErrorIcon width={40} />}
            <div className="invalid-card-expiry">
              {paymentFailureError
                ? `Card payment verification failed. 
Please check details and try again.`
                : showCardError
                ? "Card number is invalid"
                : showExpError
                ? "Expiry date is invalid"
                : showCvvError
                ? "CVV is invalid"
                : ""}
            </div>
          </div>
        </Frames>
        <div className="card-pay-button-container">
          <button
            id="pay__button"
            className={
              `card-pay-button active-button` +
              (!enablePayBtn ? " touched" : "")
            }
            onClick={onConfirmPayment}
            onMouseDown={() =>
              document.getElementById("pay__button").classList.add("touched")
            }
            disabled={!enablePayBtn}
          >
            <div className="card-pay-text">
              {showLoader ? <Loader /> : "Pay"}
            </div>
          </button>
        </div>
      </div>
    );
  };

  if (sidToken) {
    return (
      <div>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <div style={{ display: "none" }}>{renderCardDetails()}</div>
      </div>
    );
  }

  return (
    <div className="payment-options-container">
      <div className="page-title-text">Payment options</div>
      <div className="payment-options-subtitle">
        {price.convenienceFeePercentage / 100}% convenience fee on cards and
        Apple Pay
      </div>
      <ApplePayButton
        verifyPayment={applePayVerifyPayment}
        billAmount={price.billAmount / 100}
        setApplePaySession={setApplePaySession}
      />
      {renderCardDetails()}
      {/* <button
        id="payBalance__button"
        className="balance-pay-button inactive-button"
        onClick={() => {
          helperService.setItem("openPaymentLink", true);
          // setPolling(true);
          history.push({
            pathname: "/balance-processing",
            state: { id: invoiceNumber, amount: price.billAmount, paymentLink },
          });
        }}
      >
        <div className="balance-pay-text">Pay With </div>
        <BalanceLogo />
      </button> */}
      {/* 
      {userInfo.verificationStatus === "NOT_VERIFIED" && (
        <div className="non-balance-user-container">
          <div className="non-balance-user-div">
            <div className="non-balance-user-text">
              Your current wallet balance
            </div>
            <div className="non-balance-user-text">AED 4,586.75</div>
          </div>
          <div className="non-balance-user-div">
            <div className="non-balance-user-text">
              Your additional discounts
            </div>
            <div className="non-balance-user-text">20%</div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default PaymentOptions;
