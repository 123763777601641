
import { useEffect, useState } from 'react';

const useCountdown = (targetDate, onFinish) => {

  const [countDown, setCountDown] = useState(
    targetDate
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);
    if(countDown === 0){
        onFinish();
    }

    return () => clearInterval(interval);
  }, [countDown, onFinish]);

  const minutes = Math.floor((countDown / 60 ));
  const seconds = Math.floor((countDown % 60 ));

  return [minutes, seconds];
};

export { useCountdown };
