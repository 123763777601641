import axios from "axios";
import React, { useState } from "react";
import { ReactComponent as AppleLogo } from "src/assets/images/apple.svg";
import apiConstants from "src/config/env";

const ApplePayButton = ({ verifyPayment, billAmount, setApplePaySession }) => {
  // Handle initialization of Apple Pay session
  const handleApplePay = async () => {
    try {
      const session = new window.ApplePaySession(6, {
        countryCode: "SA",
        currencyCode: "AED",
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        total: {
          label: "Bill amount",
          amount: billAmount,
        },
      });
      session.begin();

      session.onvalidatemerchant = (event) => {
        // Perform server-side validation of merchant
        // and pass back merchant session object
        console.log("onvalidatemerchant", event);
        // let appleUrl = 'https://apple-pay-gateway.apple.com/paymentservices/paymentSession' //event.validationURL
        const validationURL = `${apiConstants.BASE_URL}/apple/validate?appleurl=${event.validationURL}&merchantDomain=${window.location.host}`;
        axios
          .post(validationURL, {})
          .then((response) => {
            console.log("onvalidatemerchant response", response);
            return response?.data;
          })
          .then((merchantSession) => {
            console.log("onvalidatemerchant merchantSession", merchantSession);
            session.completeMerchantValidation(merchantSession);
          })
          .catch((err) => console.log(err));
      };

      session.onpaymentauthorized = (event) => {
        // Perform server-side processing of payment
        console.log("onvalidatemerchant merchantSession", event.payment.token);

        const paymentToken = event.payment.token;
        verifyPayment(paymentToken, (outcome) => {
          console.log(
            outcome,
            "callback",
            window.ApplePaySession.STATUS_SUCCESS
          );
          if (outcome) {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          } else {
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          }
        });
      };

      setApplePaySession(session);
    } catch (error) {
      console.error("Error initializing Apple Pay session", error);
    }
  };

  if (!window.ApplePaySession) {
    return null;
  }

  return (
    <div className="card-pay-button-container">
      <button
        className={`card-pay-button active-button`}
        onClick={handleApplePay}
      >
        <AppleLogo />{" "}
        <div style={{ marginTop: "4px", marginLeft: "10px" }}>{" Pay"}</div>
      </button>
    </div>
  );
};

export default ApplePayButton;
