import React from 'react'
import RCalendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { RxChevronLeft, RxChevronRight } from 'react-icons/rx';
import './styles.css'

const Calendar = ({
    onChange,
    selectedDate,
    minDate,
    maxDate
}) => {
    const renderNextLabel = () => {
        return (
            <div className='next-prev-label'>
                <RxChevronRight color='#fff' size={25} />
            </div>
        )

    }
    const renderPrevLabel = () => {
        return (
            <div className='next-prev-label'>
                <RxChevronLeft color='#fff' size={25} />
            </div>
        )
    }

    return <div className="Sample__container">
        <main className="Sample__container__content">
            <RCalendar
                nextLabel={renderNextLabel()}
                prevLabel={renderPrevLabel()}
                calendarType="US"
                onChange={onChange}
                minDate={minDate || new Date()}
                value={selectedDate} />
        </main>
    </div>
}

export default Calendar