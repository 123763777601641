import React from "react";
import "../payment/styles.css";
import Styles from "./styles.module.css";
const OrderTotal = ({ price, showConvienience, error = false }) => {
  const {
    billAmount,
    listPrice,
    taxAmount,
    discountedAmount,
    convenienceAmount,
    discountedPercentage,
    conveniencePercentage,
  } = price;
  return (
    <div className={Styles["order-total-container"]}>
      <div className={Styles["total-title-container"]}>
        <div className={Styles["total-amount"]}>
          AED{" "}
          {showConvienience
            ? (billAmount / 100).toFixed(2)
            : ((billAmount - convenienceAmount) / 100).toFixed(2)}
        </div>
      </div>
      <div className={Styles["separator"]}></div>
      <div>
        <div className={Styles["total-split-container"]}>
          <div className={Styles["total-parameter"]}>Requested Amount</div>
          <div className={Styles["total-parameter"]}>
            AED{" "}
            {showConvienience
              ? (billAmount / 100).toFixed(2)
              : ((billAmount - convenienceAmount) / 100).toFixed(2)}
          </div>
        </div>
        <div className={Styles["total-split-container"]}>
          <div className={Styles["total-parameter"]}> Processing fee (0%)</div>
          <div className={Styles["total-parameter"]}>AED 0.00</div>
        </div>

        {/* <div className='total-split-container'>
                    <div className='total-parameter'>Tax (10%)</div>
                    <div className='total-parameter'>AED {taxAmount}</div>
                </div> */}
      </div>
      <div className={Styles["order-total-text"]}>
        Payments are not refundable
      </div>
      {!error && <div className={Styles["separator"]}></div>}
    </div>
  );
};
export default OrderTotal;
