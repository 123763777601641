import React from "react";
import { withRouter } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      style={{
        display: "block",
        position: "fixed",
        zIndex: 1031,
        top: "50%",
        right: "50%",
      }}
    >
      <p>Not Found</p>
    </div>
  );
};

export default withRouter(NotFound);
