import React from 'react'
import "./styles.css"

const OrderDetails = ({ showPaymentStatus }) => {
    const renderOrderDetails = () => {
        return (
            <div>
                <div className='table-number'>{showPaymentStatus ? 'Payment successful' : 'Table 14'}</div>
                <div className='order-text'>Tuesday, 14 March</div>
                <div className='separator'></div>
                <div className='order-text'>12:30 pm</div>
                <div className='separator'></div>
                <div className='order-text order-number-container'>
                    <div>Order no.</div>
                    <div>0032</div>
                </div>
                <div className='separator'></div>
            </div>
        )
    }
    const orderMockData = [1, 2, 3]
    const renderOrderItems = () => {
        return orderMockData.map((order, index) => {
            return (
                <div className='order-item-container' key={index}>
                    <div className='order-item-left-container'>
                        <div className='order-item-title'>Asian Vegan Veggie Rolls</div>
                        <div className='order-item-sub-title'>Rice paper, cabbage, carrot, spring onion, coriander, snow peas, soy, sesames, hoisin</div>
                    </div>
                    <div className='order-item-right-container'>
                        <div className='order-item-count'>x1</div>
                        <div className='order-amount'>AED 86.00</div>
                    </div>
                </div>
            )
        })

    }
    return (
        <div className='order-details-container'>
            {renderOrderDetails()}
            {renderOrderItems()}
            <div className='separator'></div>
        </div>
    )
}
export default OrderDetails