import React from 'react'
import './styles.css';
import { RotatingLines } from 'react-loader-spinner';
import Loader from 'src/components/loader';

const TimeSlots = ({
    formattedDate,
    availableSlotsData,
    changeSelecteeSlot,
    selectedSlot,
    slotsDataLoading
}) => {
    const renderSelectedDate = () => {
        return <div className='time-slot-date'>{formattedDate}</div>
    }
    const renderTimeSlots = () => {
        if(slotsDataLoading){
            return (<div className='no-slots-text'>
                <Loader/>
            </div>)
        }
        if(!availableSlotsData){
            return
        }
        if(availableSlotsData && availableSlotsData.length === 0){
            return <div className='no-slots-text'>No slots available on this day</div>
        }
        // const slots = ['8:00 am', '8:30 am', '9:00 am', '9:30 am', '10:00 am', '10:30 am', '11:00 am', '11:30 am', '12:00 am', '12:30 am', '01:00 pm', '01:30 pm', '02:00 pm', '02:30 pm', '03:00 pm']
        return (
            <div className='time-slot-container'>
                {
                    availableSlotsData.map(({Time, Available}, index) => {
                        if(!Available) return
                        const noMargin = index === 0 || index % 4 === 0 
                        const date = new Date(Time);
                        const hours = date.getHours();
                        const minutes = date.getMinutes();
                        const amPm = hours >= 12 ? 'pm' : 'am';
                        const formattedTime = `${(hours % 12) || 12}:${minutes.toString().padStart(2, '0')} ${amPm}`;
                        
                        return (
                            <button
                                className={`time-slot ${selectedSlot === Time ? 'time__slot-active' : ''}`}
                                key={index}
                                onClick={() => changeSelecteeSlot(Time)}
                                style={{
                                    marginLeft: noMargin ? 0 : 12
                                }}
                            >
                                {formattedTime}
                            </button>
                        )
                    })
                }

            </div>
        )
    }

    return (
        <div className='time-slots-wrapper'>
            {renderSelectedDate()}
            {renderTimeSlots()}
        </div>
    )
}

export default TimeSlots