import React from "react";
import { Images } from "src/assets/images";
import PaymentOptions from "../payment/payment-options";
import OrderTotal from "../payment/order-total";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { helperService } from "src/services/helperService";
import ErrorMessage from "src/components/ErrorMessage";
import BookkingExpireWarningModal from "src/components/BookingExpireWarningModal";
import { Chip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BookingLoaderModel from "src/components/BookingLoaderModel/BookingLoaderModel";
import AuthActions from "src/stores/auth/actions";
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ConfirmPayment = ({ history, location }) => {
  const [time, setTime] = useState(10 * 60); // Initial time in seconds
  const {
    data: { id, invoiceNumber, invoiceDate, price, paymentLink } = {},
    stylistData: {
      personalInfo: { first_name, last_name } = {},
      jobInfo: { job_name } = {},
      profilePic = "",
    },
    sessionData: { name, description, duration },
  } = location?.state || helperService.getItem("prePaymentData");
  const verifyPaymentResponse = useSelector(
    (state) => state.checkout.verifyPaymentResponse
  );

  const currentUrl = window.location.href;
  const storeData = useSelector((state) => state.auth.storeData);
  const businessName = storeData?.brand?.name;
  const businessPlace = storeData?.name;
  const logoSrc = storeData.profilePictureUrl || Images.Logo;

  const [sidToken, setSidToken] = useState(null);
  const [failureError, setFailureError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    const timerId = setTimeout(() => {
      window.localStorage.clear();
      window.location.reload();
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timerId);
    };
  }, []);

  if (time < 0) {
    window.localStorage.clear();
    window.location.reload();
  }

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  useEffect(() => {
    if (currentUrl.indexOf("success?cko-session-id=") > -1) {
      dispatch(AuthActions.OpenBookingLoadingModal(""));
      const sid_token = window.location.href.split(
        "success?cko-session-id="
      )?.[1];
      setSidToken(sid_token);
    } else if (currentUrl.indexOf("failure?cko-session-id=") > -1) {
      setFailureError(true);
    }
  }, [currentUrl]);

  console.log("failureError ==> ", failureError);

  const renderHeader = () => {
    return (
      <div className="header-container">
        <div>
          <img
            src={
              storeData?.businessStoreCardDetails?.heroBanner || Images.StoreBG
            }
            alt="store bg"
            className="header-img"
          />
          <div
            style={{
              position: "absolute",
              bottom: "-105px",
              right: "20px",
            }}
          >
            <div
              style={{
                fontFamily: "Regular",
                paddingRight: "auto",
              }}
            >
              <div>Session timer</div>
              <Chip
                sx={{
                  backgroundColor: "#FFA028",
                  color: "white",
                  ".MuiChip-label": { fontSize: "14px" },
                }}
                icon={
                  <AccessTimeIcon fontSize="small" sx={{ fill: "white" }} />
                }
                label={`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
              />
            </div>
          </div>
        </div>
        <div className="header-logo-div">
          <img src={logoSrc} alt="store logo" className="header-logo" />
          <p className="name">{businessName}</p>
          <p className="business">{businessPlace}</p>
        </div>
      </div>
    );
  };

  const renderItem = () => {
    return (
      <div className="stylelist-wrapper">
        <div key={id} className="stylelist-parent-container">
          <div className="stylist-container">
            <div className="stylist-left-container">
              {profilePic.length > 0 ? (
                <img src={profilePic} className="stylist-img" />
              ) : (
                <div className="stylist-img-bg">
                  <div className="stylist-img-bg-text">
                    {first_name[0]}
                    {last_name[0]}
                  </div>
                </div>
              )}
              <div className="stylist-details">
                <div className="stylist-name">
                  {first_name} {last_name}
                </div>
                <div>{job_name}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="separator"></div>
      </div>
    );
  };

  const renderDateTimeContainer = () => {
    const date = new Date(invoiceDate);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dateOfMonth = date.getDate();
    const monthOfYear = monthsOfYear[date.getMonth()];
    const formattedString = `${dayOfWeek}, ${dateOfMonth} ${monthOfYear}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "pm" : "am";
    const formattedTime = `${hours % 12 || 12}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;

    return (
      <div className="booking__status-date-time-container new-date-time-container">
        <div className="date-title">{formattedString}</div>
        <div className="separator"></div>
        <div className="date-title">{formattedTime}</div>
        <div className="separator"></div>
      </div>
    );
  };

  if (
    verifyPaymentResponse?.checkoutResponse?.message === "USER_INPUT_REQUIRED"
  ) {
    console.log(
      "verifyPaymentResponse",
      JSON.stringify(verifyPaymentResponse),
      localStorage.getItem("accessToken")
    );
    window.location.href = verifyPaymentResponse?.checkoutResponse?.redirectUrl;
  }

  if (failureError) {
    return (
      <div>
        <ErrorMessage wrongPage={true} history={history} />
        <div style={{ display: "none" }}>
          <PaymentOptions
            price={price}
            sidToken={sidToken}
            invoiceNumber={id}
            history={history}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="store-cat-container">
      {renderHeader()}
      <BookkingExpireWarningModal />
      <BookingLoaderModel />
      <div className="category-heading">Make your payment</div>
      <div className="booking-heading">
        <div className="booking-heading-text">{name}</div>
        <div className="booking-heading-text">{duration} min</div>
      </div>
      <div className="booking-subheading">{description}</div>
      <div className="separator width-90"></div>
      {renderItem()}
      {renderDateTimeContainer()}
      <OrderTotal
        price={price}
        showConvienience={location.pathname !== "/confirm-payment"}
      />
      <PaymentOptions
        price={price}
        sidToken={sidToken}
        invoiceNumber={id}
        history={history}
        paymentLink={paymentLink}
      />
    </div>
  );
};
export default withRouter(ConfirmPayment);
