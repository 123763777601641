import React from "react";
import Styles from "./styles.module.css";
/* eslint-disable no-undef */
// import { ReactComponent as AppleLogo } from "src/assets/images/apple.svg"
// import { ReactComponent as BalanceLogo } from "src/assets/images/balance-logo.svg";
import { ReactComponent as AmexLogo } from "src/assets/images/amex.svg";
import { ReactComponent as MasterCardLogo } from "src/assets/images/mastercard.svg";
import { ReactComponent as VisaLogo } from "src/assets/images/visa.svg";
import { ReactComponent as MaestroLogo } from "src/assets/images/maestro.svg";
import { ReactComponent as ErrorIcon } from "src/assets/images/errorIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import MerchantActions from "src/stores/merchant/actions";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import apiConstant from "src/config/env";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "src/components/loader";
import ApplePayButton from "src/containers/merchant-confirm-payment/ApplePayButton";
import axios from "axios";
// import { ReactComponent as AppleLogo } from "src/assets/images/apple.svg";
// import { helperService } from "src/services/helperService";
import ContentLoader from "react-content-loader";
import { helperService } from "src/services/helperService";
// import ApplePayButton from "src/components/common/ApplePayButton";

// console.log(MerchantActions, "MerchantActions");
// import ApplePayButton from "src/components/common/ApplePayButton";

const PaymentOptions = ({
  history,
  price,
  sidToken,
  invoiceNumber,
  paymentId,
}) => {
  const [applePaySession, setApplePaySession] = useState(null);
  console.log(price, invoiceNumber, "here id the answer");
  const dispatch = useDispatch();

  const [showLoader, setShowLoader] = useState(false);
  const [enablePayBtn, setEnablePayBtn] = useState(false);
  const [showCardError, setShowCardError] = useState(false);
  const [showExpError, setShowExpError] = useState(false);
  const [showCvvError, setShowCvvdError] = useState(false);
  const [paymentFailureError, setPaymentFailureError] = useState(false);

  const authToken = useSelector(
    (state) => state.merchant?.paymentAuthorizationSuccess?.accessToken
  );
  const data = helperService.getItem("getMerchantDeails");
  useEffect(() => {
    console.log(PaymentModule, "PaymentModule.........");
    if (!PaymentModule) return;
    PaymentModule?.initialise({
      clientId: helperService.getItem("businessUserId"),
      clientChannel: "QR",
      clientType: "BUSINESS",
      data: {
        amount: price.billAmount,
        description: "Test payment",
        referenceId: "123456789",
        transferType: "PAY_BY_BALANCE",
      },
      successCallback: function (response) {
        console.log(response);
        history.push("/merchant-payment-status");
      },
      failureCallback: function (response) {
        console.log(response);
      },
      onRequestInitiated: function (response) {
        console.log(response);
        const requestId = response?.data?.requestId;
        helperService.setItem("requestId", requestId);
      },
    });
  }, []);

  useEffect(() => {
    console.log("price", price, sidToken);
    // check all data is present or not
    if (helperService.getItem("flagForProcess") != 1) {
      return;
    }
    if (sidToken) {
      localStorage.removeItem("flagForProcess");
      const requestPayload = {
        // token: sidToken,
        // discountedAmount: price.discountedAmount,
        // convenienceAmount: price.convenienceFee,
        // taxAmount: price.taxAmount,
        // description: invoiceNumber,
        history: history,
        callBack: setShowLoader,

        destination: {
          userId: data?.qrdto?.businessUser?.id, // buisness id
          accountType: "MERCHANT",
        },
        description: data?.qrdto?.id, //QR Id
        invoice: {
          amount: data?.merchantRequestDTO?.amount,
          fee: {
            processingFee: 0,
          },
          description: "Test",
        },
        token: sidToken,
        transferType: "GUEST_PAYMENT",
        paymentChannel: "QR",
      };
      dispatch(MerchantActions.paymentProcess(requestPayload));
    }
  }, [sidToken]);

  const onConfirmPayment = () => {
    setShowLoader(true);
    Frames.submitCard();
  };

  const verifyPayment = (token, type) => {
    console.log(authToken, price, "onConfirmPayment");
    let requestPayload = {
      token: token,
      transferType: type.toUpperCase(),
      amount: price.billAmount,
      referenceId: token,
      paymentChannel: "QR",
      wallet: null,
      successUrl: apiConstant.MERCHANT_CHECKOUT_SUCCESS_URL,
      failureUrl: apiConstant.MERCHANT_CHECKOUT_FAILURE_URL,
      _3dsEnabled: type === "APPLE_PAY" ? false : true,
      setPaymentFailureError: setPaymentFailureError,
      showLoader: setShowLoader,
      paymentId,
    };
    console.log("onConfirmPayment ==> ", requestPayload);
    localStorage.setItem("flagForProcess", 1);
    dispatch(MerchantActions.paymentInitiate(requestPayload));
    document.getElementById("pay__button").classList.remove("touched");
  };

  const handleShowErrorMsg = (e) => {
    switch (e.element) {
      case "card-number":
        if (!e.isValid) {
          setShowCardError(true);
        } else {
          setShowCardError(false);
        }
        setPaymentFailureError(false);
        break;
      case "expiry-date":
        if (!e.isValid) {
          setShowExpError(true);
        } else {
          setShowExpError(false);
        }
        setPaymentFailureError(false);
        break;
      case "cvv":
        if (!e.isValid) {
          setShowCvvdError(true);
        } else {
          setShowCvvdError(false);
        }
        setPaymentFailureError(false);
        break;
      default:
        break;
    }
  };
  const appleProcessPayment = (sidToken, successCallBack) => {
    console.log(
      "appleProcessPayment",
      typeof successCallBack === "function",
      successCallBack
    );
    const requestPayload = {
      destination: {
        userId: helperService.getItem("businessUserId"), // buisness id
        accountType: "MERCHANT",
      },
      description: "",
      invoice: {
        amount: price.billAmount,
        fee: {
          processingFee: 0,
          // discountedAmount: price.discountedAmount,
          // convenienceAmount: price.convenienceFee,
          // taxAmount: price.taxAmount,
        },
        description: "Test",
      },
      token: sidToken,
      transferType: "GUEST_PAYMENT",
      paymentChannel: "QR",
      history: history,
      callBack: (bool) => {
        setShowLoader(false);
        successCallBack(bool);
      },
    };
    dispatch(MerchantActions.paymentProcess(requestPayload));
  };

  const applePayVerifyPayment = async (token, callBack) => {
    // const {version, data, signature,header} = token.paymentData
    // const response: IAddCardResponse = yield call(CheckoutService.getApplePayToken, payload)
    let cardPayload = {
      type: "applepay",
      money: price.billAmount,
      note: "apple pay",
      token_data: token.paymentData,
    };
    const response = await axios.post(
      `${apiConstant.CHECKOUT_URL}/tokens`,
      cardPayload,
      {
        headers: { Authorization: apiConstant.CHECKOUT_PUBLIC_KEY },
      }
    );
    console.log("applePayVerifyPayment", response.data, response?.data?.token);

    if (response?.data?.token) {
      console.log("coming here 345", typeof callBack === "function");
      let requestPayload = {
        token: response.data.token,
        transferType: "APPLE_PAY",
        amount: price.billAmount,
        referenceId: response.data.token,
        wallet: null,
        successUrl: apiConstant.MERCHANT_CHECKOUT_SUCCESS_URL,
        failureUrl: apiConstant.MERCHANT_CHECKOUT_FAILURE_URL,
        _3dsEnabled: false,
        paymentChannel: "QR",

        // setPaymentFailureError: setPaymentFailureError,
        showLoader: setShowLoader,
        applePayCallBack: appleProcessPayment,
        successCallBack: callBack,
      };
      console.log("onConfirmPayment ==> ", requestPayload);
      // dispatch(AuthActions.OpenBookingLoadingModal(""));
      dispatch(MerchantActions.paymentInitiate(requestPayload));
    }

    document.getElementById("pay__button").classList.remove("touched");
  };
  const renderCardDetails = () => {
    return (
      <div className={Styles["card-details-container"]}>
        <div className={Styles["card-image-container"]}>
          <VisaLogo style={{ marginRight: 20 }} />
          <MasterCardLogo style={{ marginRight: 20 }} />
          <MaestroLogo style={{ marginRight: 20 }} />
          <AmexLogo style={{ marginRight: 20 }} />
        </div>
        <Frames
          config={{
            publicKey: apiConstant.CHECKOUT_PUBLIC_KEY,
            localization: {
              cardNumberPlaceholder: "Card number",
              expiryMonthPlaceholder: "MM",
              expiryYearPlaceholder: "YY",
              cvvPlaceholder: "CVV",
            },
            style: {
              base: {
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: "20px",
                color: "#848C98",
              },
            },
          }}
          cardTokenized={(e) => {
            console.log("cardTokenized", e);
            verifyPayment(e.token, e.type);
          }}
          cardValidationChanged={(e) => {
            console.log("cardValidationChanged", e);
            if (e.isValid) {
              setEnablePayBtn(true);
            } else {
              setEnablePayBtn(false);
            }
          }}
          frameValidationChanged={(e) => {
            console.log("frameValidationChanged", e);
            handleShowErrorMsg(e);
          }}
          cardTokenizationFailed={(e) => {
            console.log("cardTokenizationFailed", e);
          }}
          paymentMethodChanged={(e) => {
            console.log("paymentMethodChanged", e);
          }}
        >
          <CardNumber
            className={
              showCardError
                ? Styles["card-number-error-input"]
                : Styles["card-number-input"]
            }
          />
          <div className={Styles["card-cvv-container"]}>
            <div className={Styles["left-container"]}>
              <span className={Styles["expiry-text"]}>Exp</span>
              <div>
                <ExpiryDate
                  className={
                    showExpError
                      ? Styles["card-expiry-error"]
                      : Styles["card-expiry"]
                  }
                />
              </div>
            </div>
            <div>
              <Cvv
                className={
                  showCvvError ? Styles["card-cvv-error"] : Styles["card-cvv"]
                }
              />
            </div>
          </div>
          <div
            className={Styles["invalid-container"]}
            style={{
              display:
                paymentFailureError ||
                showCardError ||
                showExpError ||
                showCvvError
                  ? "flex"
                  : "none",
            }}
          >
            {(paymentFailureError ||
              showCardError ||
              showExpError ||
              showCvvError) && <ErrorIcon width={40} />}
            <div className={Styles["invalid-card-expiry"]}>
              {paymentFailureError
                ? `Card payment verification failed.
  Please check details and try again.`
                : showCardError
                ? "Card number is invalid"
                : showExpError
                ? "Expiry date is invalid"
                : showCvvError
                ? "CVV is invalid"
                : ""}
            </div>
          </div>
        </Frames>
        <div
          className={Styles["card-pay-button-container"]}
          style={{
            display: enablePayBtn ? "block" : "none",
            transition: "opacity 0.3s ease",
            marginBottom: 0,
          }}
        >
          <button
            id="pay__button"
            className={`${Styles["card-pay-button"]} ${
              Styles[`active-button`]
            }`}
            onClick={onConfirmPayment}
            onMouseDown={() =>
              document.getElementById("pay__button").classList.add("touched")
            }
          >
            <div className={Styles["card-pay-text"]}>
              {showLoader ? <Loader /> : "Pay"}
            </div>
          </button>
        </div>
      </div>
    );
  };

  if (sidToken) {
    return (
      <div>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <div style={{ display: "none" }}>{renderCardDetails()}</div>
        <div id="payment-container" />
      </div>
    );
  }

  return (
    <div className={Styles["payment-options-container"]}>
      <div className={Styles["page-title-text"]}>Payment options</div>
      <ApplePayButton
        verifyPayment={applePayVerifyPayment}
        billAmount={price.billAmount / 100}
        setApplePaySession={setApplePaySession}
      />
      {renderCardDetails()}
      <div id="payment-container" />
    </div>
  );
};

export default PaymentOptions;
