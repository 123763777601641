// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-wrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.confirm__button{
    background-color: #000;
    min-height: 56px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Regular';
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border:none;
    border-radius: 20px;
    color: #fff;
    width: 200px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/containers/booking-slot/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".slot-wrapper{\n    width: 90%;\n    display: flex;\n    flex-direction: column;\n    align-self: center;\n}\n.confirm__button{\n    background-color: #000;\n    min-height: 56px;\n    margin-top: 40px;\n    margin-bottom: 40px;\n    font-family: 'Regular';\n    font-size: 20px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-self: center;\n    border:none;\n    border-radius: 20px;\n    color: #fff;\n    width: 200px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
