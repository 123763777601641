import React from "react";
import Box from "@mui/material/Box";
import "./index.css";
import Modal from "@mui/material/Modal";
import { ReactComponent as ClockIcon } from "src/assets/images/clock.svg";
import { useDispatch, useSelector } from "react-redux";
import AuthAction from "src/stores/auth/actions";

const BookingExpireWarningModal = () => {
  const isBookingExpireWarningModalOpen = useSelector(
    (state) => state?.auth.isBookingExpireWarningModalOpen
  );
  const disptach = useDispatch();
  console.log(
    isBookingExpireWarningModalOpen,
    "isBookingExpireWarningModalOpen"
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "#F1F4F6",
    boxShadow: 24,
    borderRadius: "16px",
    display: "flex",
    p: 4,
    paddingTop: 6,
    paddingBottom: 6,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div>
      <Modal
        open={isBookingExpireWarningModalOpen}
        style={{ maxWidth: "460px", margin: "auto", padding: "60% 5%" }}
      >
        <Box sx={style}>
          <ClockIcon />
          <div className="booking-warning-heading-txt">
            Payment page will expire in 10 minutes
          </div>
          <div className="booking-warning-sub-heading-txt">
            Please make a payment to reserve your booking slot before session
            time out. Bookings without payment will be cancelled.
          </div>
          <div className="booking-warning-sub-heading-txt">
            Close to continue.
          </div>
          <button
            id="login__btn"
            className="nextBtn"
            onClick={() =>
              disptach(AuthAction.CloseBookingExpireWarningModal(""))
            }
            onMouseDown={() =>
              document.getElementById("login__btn").classList.add("touched")
            }
            style={{ marginTop: "36px" }}
          >
            <p className="nextBtnTxt">Close</p>
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default BookingExpireWarningModal;
