import React from "react";
import PaymentOptions from "./payment-options";
import OrderTotal from "./order-total";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { useState } from "react";
import ErrorMessageQR from "src/components/ErrorMessageQR";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import MerchantAction from "src/stores/merchant/actions";
import { helperService } from "src/services/helperService";
import PaymentNotFound from "src/containers/payment-not-found";
import ContentLoader from "react-content-loader";
import BookingLoaderModel from "src/containers/merchant-confirm-payment/BookingLoaderModel/BookingLoaderModel";
import Styles from "./styles.module.css";
const MerchantConfirmPayment = ({ history, location }) => {
  const [sidToken, setSidToken] = useState(null);
  const [failureError, setFailureError] = useState(false);

  const {
    businessUserId = helperService.getItem("businessUserId"),
    id: paymentId = helperService.getItem("paymentId"),
  } = queryString.parse(location.search);
  console.log("IDSSSSSSS", paymentId, businessUserId);
  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state.merchant?.getMerchantPaymentDetailsSuccess
  );

  const getMerchantPaymentDetailsError = useSelector(
    (state) => state.merchant.getMerchantPaymentDetailsError
  );

  const paymentAuthorizationError = useSelector(
    (state) => state.merchant.paymentAuthorizationError
  );

  const paymentInitiateError = useSelector(
    (state) => state.merchant.paymentInitiateError
  );

  const paymentProcessError = useSelector(
    (state) => state.merchant.paymentProcessError
  );

  const initiateResponse = useSelector(
    (state) => state.merchant.paymentInitiateSuccess
  );
  const getMerchantPaymentDetailsLoading = useSelector(
    (state) => state.merchant?.getMerchantPaymentDetailsLoading
  );

  const paymentProcessLoading = useSelector(
    (state) => state.merchant.paymentProcessLoading
  );

  console.log("useselectorrrrrr", data);

  const { id, price, paymentLink } = {
    bookingConfirmed: true,
    id: "ca559841-d17a-4d69-b012-73910f4612b7",
    invoiceDate: "2023-05-21T18:00:00",
    invoiceNumber: "63241",
    invoiceNumberPrefix: "Y12",
    paymentLink: "https://balanceapp.page.link/BMLR566aJnbX3x3U9",
    price: {
      billAmount: data?.merchantRequestDTO?.amount,
      convenienceAmount: 1500,
      conveniencePercentage: 300,
      discountedAmount: 0,
      discountedPercentage: 0,
      listPrice: 50000,
      tipAmount: 0,
    },
  };

  const currentUrl = window.location.href;

  const businessName = data?.qrdto?.store?.brand?.name;
  const logoSrc = data?.qrdto?.store?.logoUrl?.trim();
  const profilePictureUrl = data?.qrdto?.store?.profilePictureUrl;
  const businessPlace = data?.qrdto?.store?.name;
  useEffect(() => {
    if (currentUrl.indexOf("success?cko-session-id=") > -1) {
      const sid_token = window.location.href.split(
        "success?cko-session-id="
      )?.[1];
      setSidToken(sid_token);
    } else if (currentUrl.indexOf("failure?cko-session-id=") > -1) {
      setFailureError(true);
      localStorage.removeItem("flagForProcess");
    }
  }, [currentUrl]);

  console.log("failureError ==> ", failureError);

  useEffect(() => {
    if (businessUserId && paymentId) {
      helperService.setItem("paymentId", paymentId);
      helperService.setItem("businessUserId", businessUserId);
      dispatch(
        MerchantAction.getMerchantPaymentDetails({
          businessUserId,
          paymentId,
          history,
        })
      );
    }
  }, [businessUserId, paymentId]);

  if (getMerchantPaymentDetailsError) {
    return (
      <div style={{ height: "80%", display: "flex", alignItems: "center" }}>
        <PaymentNotFound />
        <div style={{ display: "none" }}>
          <PaymentOptions
            price={price}
            sidToken={sidToken}
            invoiceNumber={id}
            history={history}
          />
        </div>
      </div>
    );
  }
  if (getMerchantPaymentDetailsLoading) {
    return (
      <div
        className={Styles["store-cat-container"]}
        style={{ paddingTop: "40%" }}
      >
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <div style={{ display: "none" }}>
          <PaymentOptions
            price={price}
            sidToken={""}
            invoiceNumber={id}
            history={history}
            paymentLink={paymentLink}
          />
        </div>
      </div>
    );
  }

  if (initiateResponse?.checkoutResponse?.message === "USER_INPUT_REQUIRED") {
    console.log(
      "verifyPaymentResponse",
      JSON.stringify(initiateResponse),
      localStorage.getItem("accessToken")
    );
    window.location.href = initiateResponse?.checkoutResponse?.redirectUrl;
  }

  if (
    failureError ||
    paymentAuthorizationError ||
    paymentInitiateError ||
    paymentProcessError
  ) {
    return (
      <div style={{ backgroundColor: "black", width: "100%" }}>
        <ErrorMessageQR
          wrongPage={true}
          history={history}
          price={price}
          setFailureError={() => setFailureError(false)}
        />
        <div style={{ display: "none" }}>
          <PaymentOptions
            price={price}
            sidToken={""}
            invoiceNumber={id}
            history={history}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <ErrorMessage wrongPage /> */}
      <div className={Styles["store-cat-container"]}>
        <BookingLoaderModel />
        {!paymentProcessLoading && (
          <div className={Styles["category-heading"]}>Make your payment</div>
        )}

        {<OrderTotal price={price} showConvienience={true} />}

        <PaymentOptions
          price={price}
          sidToken={sidToken}
          invoiceNumber={id}
          history={history}
          paymentId={paymentId}
        />
      </div>
    </>
  );
};

export default withRouter(MerchantConfirmPayment);

// const data = JSON.parse(`{
//   "success": true,
//   "status": "OK",
//   "data": {
//       "qrdto": {
//           "id": "369c97ae-0852-4acf-87f4-6c440ef547d9",
//           "account": {
//               "id": "a1539b8f-49bb-4d74-9326-441966cfba43",
//               "type": "MERCHANT",
//               "wallets": []
//           },
//           "store": {
//               "id": "58d4b945-518d-4765-8935-96e68e684df7",
//               "name": "DIFC",
//               "brand": {
//                   "id": "8a86b7a8-0872-4443-a9b1-93d43f059836",
//                   "name": "Y12"
//               },
//               "balanceKey": "Y1211",
//               "costCategory": "",
//               "profilePictureUrl": "https://balancestaging.blob.core.windows.net/storelogo/58d4b945-518d-4765-8935-96e68e684df7/_1681813022963.png",
//               "logoUrl": "   ",
//               "logoUrlDark": "https://balancestaging.blob.core.windows.net/storelogo/58d4b945-518d-4765-8935-96e68e684df7/difc_dark_1681813039239.png",
//               "customBackground": "",
//               "status": "ACTIVE",
//               "website": "",
//               "description": "Y12, the first Italian, unisex hair salon in Dubai is once again innovating the industry with a brand new concept in its new location at ICD Brookfield Place in DIFC.",
//               "businessStoreCardDetails": {
//                   "id": "27d34590-57d4-4326-a776-aa02a08816dc",
//                   "backgroundColor": "#12a4dd",
//                   "backgroundColorDark": "#12a4dd",
//                   "fontColor": "#FFFFFF",
//                   "fontColorDark": "#FFFFFF",
//                   "overlapLogo": "https://balancestaging.blob.core.windows.net/privilegescards/store/58d4b945-518d-4765-8935-96e68e684df7/overlaplogo_light_1681812998855.png",
//                   "overlapLogoDark": "https://balancestaging.blob.core.windows.net/privilegescards/store/58d4b945-518d-4765-8935-96e68e684df7/overlaplogo_dark_1681812998905.png",
//                   "heroBanner": "https://balancestaging.blob.core.windows.net/privilegescards/store/58d4b945-518d-4765-8935-96e68e684df7/herobanner_1681812998919.png"
//               }
//           },
//           "businessUser": {
//               "id": "00f6f03c-6ef5-407c-b06d-334db080b6f4",
//               "fullName": "Amulya Verma",
//               "loginId": "yadminnew",
//               "role": "ADMIN",
//               "status": "ACTIVE",
//               "phoneCountryCode": "91",
//               "phoneSubscriberNumber": "9871348006"
//           },
//           "createdAt": 1684761421000,
//           "updatedAt": 1684761421000
//       },
//       "merchantRequestDTO": {
//           "id": "77dbf859-8a8a-4c9e-b05f-35561b1d12f3",
//           "creatorWallet": {
//               "id": "72859076-7572-4d05-b310-8ff29267c627",
//               "currency": "AED",
//               "blockedBalance": 10032418,
//               "balance": 10593292,
//               "category": "PRIMARY",
//               "cashoutStatus": "AVAILABLE",
//               "createdAt": 1681812692000,
//               "updatedAt": 1685002223000
//           },
//           "amount": 10000,
//           "discountedAmount": 10000,
//           "offers": [],
//           "createdBy": {
//               "id": "00f6f03c-6ef5-407c-b06d-334db080b6f4",
//               "fullName": "Amulya Verma",
//               "loginId": "yadminnew",
//               "role": "ADMIN",
//               "status": "ACTIVE",
//               "phoneCountryCode": "91",
//               "phoneSubscriberNumber": "9871348006"
//           },
//           "status": "PENDING"
//       }
//   }
// }`).data;

// identifier=merchant_payment

// invoiceId=ca559841-d17a-4d69-b012-73910f4612b7&

// bookingreference=Y1263241&

//storeId=58d4b945-518d-4765-8935-96e68e684df7&

//userId=0c6412bb-a9b7-469d-bf1f-60cabf148a75&

// guestId=50997c74-42b4-40cc-b2a3-25486539fde1&

// businessUserId=19911320-1d61-4c8b-8e8f-8b06773151ee&

// businessAccountId=a1539b8f-49bb-4d74-9326-441966cfba43&

// amount=50000

// discountedAmount=0

// tipAmount=0
