import React, { createRef } from "react";
import OrderTotal from "../payment/order-total";
import PaymentSummary from "../payment/payment-summary";
import { useEffect } from "react";
import { ReactComponent as Download } from "src/assets/images/download.svg";
import AddToCalendarButton from "src/components/AddToCalendarButton/AddToCalendarButton";
import { helperService } from "src/services/helperService";
import { withRouter } from "react-router";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BookingReferenceInfo = (props) => {
  const fetchSMSBooking = props.location.state || {};
  console.log(props.history, "fetchSMSBooking");

  useEffect(() => {
    return () => {
      window.localStorage.clear();
    };
  }, []);

  if (Object.keys(fetchSMSBooking).length === 0) {
    return <div></div>;
  }
  const {
    stylistData: {
      personalInfo: { first_name, last_name } = {},
      jobInfo: { job_name } = {},
      profilePic = "",
    } = {},
    sessionData: { duration } = {},
  } = helperService.getItem("prePaymentData") || {};

  console.log("alndldanslndsl", props);

  const {
    booking_details: {
      bookingReference: invoiceNumber,
      slotTime: invoiceDate,
      stylistId: id,
      stylistName,
      itemDescription: description,
      itemName: name,
    } = {},
    guestTransaction = {},
    transfer: { createdAt } = {
      createdAt: "",
    },
  } = fetchSMSBooking;
  let last4 = "123";

  // console.log(
  //   invoiceNumber,
  //   invoiceDate,
  //   id,
  //   job_name,
  //   name,
  //   description,
  //   duration,
  //   type,
  //   scheme,
  //   last4
  // );
  const getDateString = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hour = date.getHours();
    let minute = date.getMinutes();
    const amPm = hour >= 12 ? "pm" : "am";

    // Convert hour to 12-hour format
    hour = hour % 12;
    hour = hour ? hour : 12;

    // Add leading zero to minutes if needed
    minute = minute < 10 ? "0" + minute : minute;

    return `${day} ${monthsOfYear[monthIndex]} ${year}, ${hour}:${minute} ${amPm}`;
  };

  const calendarEventData = {
    title: name,
    description,
    startDate: invoiceDate ? new Date(invoiceDate) : new Date(),
    durationInMinutes: 60,
  };

  const renderTitle = () => {
    return <div className="booking__status-title">Booking Successful</div>;
  };

  const ref = createRef(null);

  const getImage = window.print;

  const renderBookingReference = () => {
    return (
      <div className="booking__status-reference-container">
        <div className="number-wrapper">
          <div className="title">Booking ref no.</div>
          <div className="title">
            #{invoiceNumber ? invoiceNumber.split("Y12").join("") : "N/A"}
          </div>
        </div>
        <div className="number-wrapper" style={{ marginTop: "20px" }}>
          <div className="title">{name}</div>
        </div>
        <div className="sub-text">
          {/* Applying hair dye to the roots will enable your hair colour to not only last longer but also continue to look fresh and beautiful. */}
          {description}
        </div>
      </div>
    );
  };
  const renderDateTimeContainer = () => {
    const date = new Date(invoiceDate);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dateOfMonth = date.getDate();
    const monthOfYear = monthsOfYear[date.getMonth()];
    const formattedString = `${dayOfWeek}, ${dateOfMonth} ${monthOfYear}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "pm" : "am";
    const formattedTime = `${hours % 12 || 12}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;

    return (
      <div className="booking__status-date-time-container">
        <div className="separator"></div>
        <div className="date-title">{formattedString}</div>
        <div className="separator"></div>
        <div className="date-title">{formattedTime}</div>
      </div>
    );
  };

  const renderStylist = () => {
    return (
      <div className="stylelist-new-wrapper">
        <div key={id} className="stylelist-parent-container">
          <div className="stylist-container">
            <div className="stylist-left-container">
              {profilePic.length > 0 ? (
                <img src={profilePic} className="stylist-img" />
              ) : (
                <div className="stylist-img-bg">
                  <div className="stylist-img-bg-text">
                    {stylistName?.split(" ")[0][0]}
                    {stylistName?.split(" ")[1][0]}
                  </div>
                </div>
              )}
              <div className="stylist-details">
                <div className="stylist-name">{stylistName}</div>
                <div>{job_name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="booking__status-container" ref={ref}>
      <div className="booking__status-inner-container">
        {renderTitle()}
        {renderBookingReference()}
        <div className="separator"></div>
        {renderStylist()}
        {renderDateTimeContainer()}
      </div>

      <div className="separator"></div>
      <div
        style={{
          backgroundColor: "#f2f4f6",
          alignSelf: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            marginTop: "18px",
          }}
        >
          <AddToCalendarButton calendarEvent={calendarEventData} />
        </div>
        <div className="separator"></div>
        <OrderTotal price={{ ...guestTransaction }} showConvienience={true} />
        <PaymentSummary
          dateString={getDateString(createdAt)}
          cardName={"PAY_BY_BALANCE"}
          last4={""}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <button
            className="save-booking-btn"
            onClick={() => {
              window.print();
            }}
          >
            <Download /> <div className="add-cal-btn-txt">Save booking</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BookingReferenceInfo);
