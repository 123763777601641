import React, { createRef } from "react";
import OrderTotal from "./order-total";
import PaymentSummary from "./payment-summary";
//import "./styles.css";
import { useEffect } from "react";
import { helperService } from "src/services/helperService";
import { useDispatch, useSelector } from "react-redux";
// import { helperService } from "src/services/helperService";
// import { ReactComponent as Download } from "src/assets/images/download.svg";
// import AddToCalendarButton from "src/components/AddToCalendarButton/AddToCalendarButton";
import MerchantAction from "src/stores/merchant/actions";
import ContentLoader from "react-content-loader";
import Styles from "./styles.module.css";
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MerchantPaymentStatus = (props) => {
  const transactionNo = helperService.getItem("requestId");

  const paymentDetails = helperService.getItem("getPaymentDetails") || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MerchantAction.getPaymentDetails(transactionNo));
  }, [transactionNo]);

  const { guestTransaction: price = {} } = paymentDetails || {};

  console.log(paymentDetails);

  const {
    referenceId: id,
    createdAt,
    type,
    externalTransfer: {
      paymentMethod = "",
      paymentMethodMeta: {
        card: { scheme, last4 } = { scheme: "", last4: "" },
      } = {},
    } = {},
  } = paymentDetails?.transfer || {};

  console.log(
    useSelector((state) => state.merchant.getPaymentDetailsSuccess),
    paymentDetails,
    helperService.getItem("getPaymentDetails")
  );
  console.log(props.location.state, "BookingStatus");
  console.log(scheme, paymentMethod, type, "om DETAILSSSS");

  const renderTitle = () => {
    return (
      <div
        style={{
          width: "90%",
          fontSize: "36px",
          margin: "20% auto 0",
          textAlign: "center",
          fontFamily: "Light",
          color: "#15AD31",
        }}
      >
        Payment Successful
      </div>
    );
  };

  const getDateString = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hour = date.getHours();
    let minute = date.getMinutes();
    const amPm = hour >= 12 ? "pm" : "am";

    // Convert hour to 12-hour format
    hour = hour % 12;
    hour = hour ? hour : 12;

    // Add leading zero to minutes if needed
    minute = minute < 10 ? "0" + minute : minute;

    return `${day} ${monthsOfYear[monthIndex]} ${year}, ${hour}:${minute} ${amPm}`;
  };

  const dateString = getDateString(createdAt);

  if (Object.keys(paymentDetails).length == 0) {
    return (
      <div className="store-cat-container" style={{ paddingTop: "40%" }}>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
      </div>
    );
  }

  return (
    <div className={Styles["booking__status-container"]}>
      <div className={Styles["booking__status-inner-container"]}>
        {renderTitle()}
        <OrderTotal price={{ ...price, transactionNo: "#" + id }} />
      </div>

      <div
        style={{
          backgroundColor: "black",
          alignSelf: "center",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PaymentSummary
          dateString={dateString}
          cardName={paymentMethod === "CARD" ? scheme : paymentMethod}
          last4={last4}
        />
      </div>
    </div>
  );
};

export default MerchantPaymentStatus;

// let c = {
//   success: true,
//   status: "OK",
//   data: {
//     transfer: {
//       id: 113726,
//       from: "69efbfbd-efbf-bd4c-efbf-bdefbfbd11ef",
//       sourceWallet: {
//         id: "03461535-efbf-bdef-bfbd-11efbfbd5602",
//         currency: "AED",
//         blockedBalance: 0,
//         balance: 6245643,
//         category: "PRIMARY",
//         cashoutStatus: "AVAILABLE",
//         createdAt: 1654103162000,
//         updatedAt: 1686368109000,
//       },
//       fromCurrency: "AED",
//       fromAmount: 10000,
//       to: "a1539b8f-49bb-4d74-9326-441966cfba43",
//       destinationWallet: {
//         id: "72859076-7572-4d05-b310-8ff29267c627",
//         currency: "AED",
//         blockedBalance: 11996019,
//         balance: 12529600,
//         category: "PRIMARY",
//         cashoutStatus: "AVAILABLE",
//         createdAt: 1681812692000,
//         updatedAt: 1686368109000,
//       },
//       toCurrency: "AED",
//       toAmount: 10000,
//       type: "GUEST_PAYMENT",
//       status: "SUCCESS",
//       description: "b3d6517a-de2b-4c8e-9e74-86391dc6bc94",
//       referenceId: "102306101137267289",
//       externalTransfer: {
//         id: 3804,
//         amount: 10000,
//         externalReferenceId: "916426887258",
//         type: "GUEST_PAYMENT",
//         status: "SUCCESS",
//         paymentPlatform: "CHECKOUT",
//         paymentMethod: "CARD",
//         paymentMethodMeta: {
//           card: {
//             last4: "4242",
//             scheme: "Visa",
//             type: "CREDIT",
//             bank: "",
//           },
//         },
//         description: "b3d6517a-de2b-4c8e-9e74-86391dc6bc94",
//       },
//       createdAt: 1686368108000,
//       updatedAt: 1686368109000,
//     },
//     transactionCategory: "MONETARY",
//     transactionType: "CREDIT",
//     status: "SUCCESS",
//     serviceFee: 0,
//     serviceTax: 0,
//     guestTransaction: {
//       listPrice: 10000,
//       billAmount: 10000,
//       amountBlockedOnRevenue: 100,
//       discountedAmount: 0,
//       discountPercentage: 0,
//       merchantBillAmount: 9895,
//       businessTip: 0,
//       convenienceFeePercentage: 0,
//       convenienceFee: 0,
//       mdrOnBusinessBill: 100,
//       mdrOnTipBill: 0,
//       vatOnConvenienceFee: 0,
//       vatOnBusinessBill: 5,
//       vatOnTip: 0,
//     },
//     tipAmount: 0,
//   },
// }.data;
