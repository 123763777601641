import React from "react";
import { ReactComponent as ErrorStatus } from "src/assets/images/ErrorStatus.svg";
import { ReactComponent as WrongStatus } from "src/assets/images/Wrong.svg";
import "./styles.css";
import { helperService } from "src/services/helperService";

const ErrorMessage = ({ wrongPage = false, history }) => {
  return (
    <div className="error-container" style={wrongPage ? {marginTop: '180px'}:{}}>
      {wrongPage ? <WrongStatus /> : <ErrorStatus />}
      <div className="error-heading-txt">
        {wrongPage ? "Something went wrong" : "This page could not be loaded."}
      </div>
      {wrongPage ? (
        <button className='nextBtn' style={{marginTop: '20px'}} onClick={()=>{
            const prePaymentData = helperService.getItem('prePaymentData')
            history.push({ pathname: "/confirm-payment", state: prePaymentData })
        }}> <p className='nextBtnTxt'>Try again</p> </button>
      ) : (
        <div className="error-sub-heading-txt">
          Please refresh page or restart browser.
        </div>
      )}
    </div>
  );
};

export default ErrorMessage;
