import React, { useCallback } from 'react'
import './styles.css'
import OtpInput from 'react-otp-input';
import AuthActions from "src/stores/auth/actions"
import { withRouter } from 'react-router';
import { useReadOTP } from "react-read-otp";
import useDetectKeyboardOpen from 'src/hooks/useDetectKeyboardOpen';
import { useDispatch, useSelector } from 'react-redux';
import { useCountdown } from 'src/hooks/useCountdown';
import { ReactComponent as ErrorMessage } from "src/assets/images/error_message.svg"


const CountDown = ({ until, onFinish }) => {
    const [minutes, sec] = useCountdown(until, onFinish)
    return (<p className='tnc'>Resend code in {minutes.toString().length <= 1 ? '0' + minutes : minutes}:{sec.toString().length <= 1 ? '0' + sec : sec}</p>)
}

const OTPScreen = (props) => {
    const [otp, setOtp] = React.useState("")
    useReadOTP(setOtp);
    const { mobileNumber: { subscriberNumber = '', countryCode = '' } = {} } = props?.location?.state || {}
    const resendOtpWaitTime = 59
    const errorTimer = 600
    const [seconds, setSeconds] = React.useState(resendOtpWaitTime)
    const verifyPinError = useSelector((state) => state.auth.verifyPinError)
    console.log("verifyPinError", verifyPinError)
    const { message = '', errorCode = '', status = '' } = verifyPinError || {}

    const isKeyboardOpen = useDetectKeyboardOpen()
    const dispatch = useDispatch()
    const handleOtpSubmit = useCallback(() => {
        document.getElementById("otp__button").classList.add('touched')
        if (otp.length === 6) {
            setTimeout(() => {
                dispatch(AuthActions.verifyLogin({
                    input: otp,
                    phoneNumber: {
                        countryCode: countryCode,
                        subscriberNumber: subscriberNumber
                    },
                    history: props.history,
                }))
            }, 100)
        }
    }, [countryCode, dispatch, otp, props.history, subscriberNumber])

    const handleResendOtp = () => {
        setSeconds(resendOtpWaitTime)
        dispatch(AuthActions.resendOtp())
    }

    // React.useEffect(() => {
    //     handleOtpSubmit()
    // }, [handleOtpSubmit])


    const renderOTPSection = () => {
        return (
            <div className='otp-inner-container'>
                <div className='otp-title'>
                    {`Please insert the code sent to `}
                </div>
                <div className='otp-number'>
                    {'+' + countryCode + ' ' + subscriberNumber}
                </div>
                <div className='otp-number'>
                    <button className='change-number-button' onClick={() => props.history.goBack()}>Change your number</button>
                </div>
                <OtpInput
                    isInputNum={true}
                    value={otp}
                    onChange={(value) => setOtp(value)}
                    numInputs={6}
                    renderSeparator={<div className='otp-separator'></div>}
                    containerStyle={{ width: '90%', marginTop: 70, justifyContent: 'space-around' }}
                    renderInput={(props) => {
                        if (props.value.length === 0) {
                            return <input {...props} style={{ border: 'none', borderBottom: '1px solid #ccc', width: '28px', height: 40, fontFamily: 'Light', fontSize: '28px', lineHeight: '32px', backgroundColor: 'transparent', borderRadius: 0, textAlign: "left", paddingBottom: '8px' }} pattern="\d*" />
                        } else {
                            return <input {...props} style={{ border: 'none', borderBottom: '1px solid #0095f6', width: '28px', height: 40, fontFamily: 'Light', fontSize: '28px', lineHeight: '32px', backgroundColor: 'transparent', borderRadius: 0, textAlign: "center", paddingBottom: '8px' }} pattern="\d*" />
                        }
                    }}
                />
            </div>
        )
    }

    const renderBottomSection = () => {
        return (
            <div className={!isKeyboardOpen ? 'otp-bottom-div otp-bottom-div-absolute' : (otp.length === 6 ? 'otp-bottom-div otp-div-absolute2' : 'otp-bottom-div otp-div-absolute1')}>

                <div>
                    {!seconds ?
                        <p className='resend-otp' onClick={handleResendOtp}>
                            Resend code
                        </p>
                        : <CountDown until={seconds} onFinish={() => {
                            setSeconds('')
                        }} />
                    }
                </div>
                <div>
                    <button id='otp__button' className='nextBtn' onClick={handleOtpSubmit} disabled={!otp || otp.length < 6} onMouseDown={() =>
                        document.getElementById("otp__button").classList.add('touched')
                    }> <p className='nextBtnTxt'>Next</p> </button>
                </div>

            </div>
        )
    }

    const renderInCorrectOTP = () => {
        return (
            <div className='incorrect-otp-container'>
                <ErrorMessage style={{ paddingRight: "7px" }} />
                <div className='incorrect-otp'>Sorry, the OTP you entered is incorrect.{'\n'}
                    Please check and try again.</div>
            </div>
        )
    }

    return (
        <div className='otp-container'>
            {renderOTPSection()}
            {errorCode && renderInCorrectOTP()}
            {renderBottomSection()}
        </div>
    )
}
export default withRouter(OTPScreen)