import React from 'react'
import OrderDetails from './order-details'
import OrderTotal from './order-total'
import PaymentSummary from './payment-summary'
import './styles.css'

const PaymentStatus = () => {
    return (
        <div className='payment-container'>
            <OrderDetails showPaymentStatus={true} />
            <OrderTotal />
            <PaymentSummary/>
        </div>
    )
}

export default PaymentStatus