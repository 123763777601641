import React, { createRef } from "react";
import OrderTotal from "../payment/order-total";
import PaymentSummary from "../payment/payment-summary";
import "./styles.css";
import { helperService } from "src/services/helperService";
import { useEffect } from "react";
import { ReactComponent as Download } from "src/assets/images/download.svg";
import AddToCalendarButton from "src/components/AddToCalendarButton/AddToCalendarButton";
import { useDispatch, useSelector } from "react-redux";
import CheckoutAction from "src/stores/checkout/actions";
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BookingStatus = (props) => {
  console.log("alndldanslndsl", props);
  const transactionNo = helperService.getItem("partnerRequestId");
  const data = useSelector(
    (state) => state.checkout.getPartnerPaymentDetailsSuccess
  );
  const paymentDetails =
    helperService.getItem("getPartnerPaymentDetails") || data || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CheckoutAction.getPartnerPaymentDetails(transactionNo));
  }, [transactionNo]);
  const {
    data: { price, invoiceNumber, invoiceDate } = {},
    stylistData: {
      personalInfo: { id, first_name, last_name } = {},
      jobInfo: { job_name } = {},
      profilePic = "",
    } = {},
    sessionData: { name, description, duration } = {},
  } = helperService.getItem("prePaymentData") || {};
  console.log(props.location.state, "BookingStatus");

  const calendarEventData = {
    title: name,
    description,
    startDate: new Date(invoiceDate),
    durationInMinutes: duration,
  };

  // const {
  //   // cardDetails: { scheme, last4 } = {},
  //   transactionDetails: {
  //     transfer: {
  //       createdAt,
  //       externalTransfer: { paymentMethod = "" } = {},
  //       type,
  //     } = {},
  //   } = {},
  // } = props.location.state || {};

  const {
    createdAt,
    type,
    externalTransfer: {
      paymentMethod = "",
      paymentMethodMeta: {
        card: { scheme, last4 } = { scheme: "", last4: "" },
      } = {},
    } = {},
  } = paymentDetails?.transfer || {};
  console.log(paymentDetails, "paymentDetails", type, paymentMethod, scheme);

  const renderTitle = () => {
    return <div className="booking__status-title">Booking Successful</div>;
  };

  const ref = createRef(null);

  const getImage = window.print;

  const getDateString = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hour = date.getHours();
    let minute = date.getMinutes();
    const amPm = hour >= 12 ? "pm" : "am";

    // Convert hour to 12-hour format
    hour = hour % 12;
    hour = hour ? hour : 12;

    // Add leading zero to minutes if needed
    minute = minute < 10 ? "0" + minute : minute;

    return `${day} ${monthsOfYear[monthIndex]} ${year}, ${hour}:${minute} ${amPm}`;
  };

  const dateString = getDateString(createdAt);

  const renderBookingReference = () => {
    return (
      <div
        className="booking__status-reference-container"
        style={{ width: "100%" }}
      >
        <div
          className="number-wrapper"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title">Booking ref no.</div>
          <div className="title">#{invoiceNumber}</div>
        </div>
        <div className="number-wrapper" style={{ marginTop: "20px" }}>
          <div className="title">{name}</div>
        </div>
        <div className="sub-text">
          {/* Applying hair dye to the roots will enable your hair colour to not only last longer but also continue to look fresh and beautiful. */}
          {description}
        </div>
      </div>
    );
  };
  const renderDateTimeContainer = () => {
    const date = new Date(invoiceDate);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dateOfMonth = date.getDate();
    const monthOfYear = monthsOfYear[date.getMonth()];
    const formattedString = `${dayOfWeek}, ${dateOfMonth} ${monthOfYear}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "pm" : "am";
    const formattedTime = `${hours % 12 || 12}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;

    return (
      <div className="booking__status-date-time-container">
        <div className="separator"></div>
        <div className="date-title">{formattedString}</div>
        <div className="separator"></div>
        <div className="date-title">{formattedTime}</div>
      </div>
    );
  };
  const renderStylist = () => {
    return (
      <div className="stylelist-new-wrapper">
        <div key={id} className="stylelist-parent-container">
          <div className="stylist-container">
            <div className="stylist-left-container">
              {profilePic.length > 0 ? (
                <img src={profilePic} className="stylist-img" />
              ) : (
                <div className="stylist-img-bg">
                  <div className="stylist-img-bg-text">
                    {first_name?.[0]}
                    {last_name?.[0]}
                  </div>
                </div>
              )}
              <div className="stylist-details">
                <div className="stylist-name">
                  {first_name} {last_name}
                </div>
                <div>{job_name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="booking__status-container" ref={ref}>
      <div className="booking__status-inner-container">
        {renderTitle()}
        {renderBookingReference()}
        <div className="separator"></div>
        {renderStylist()}
        {renderDateTimeContainer()}
        <div className="separator"></div>
      </div>
      <div
        style={{
          backgroundColor: "#f2f4f6",
          alignSelf: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            marginTop: "18px",
          }}
        >
          <AddToCalendarButton calendarEvent={calendarEventData} />
        </div>
        <div className="separator"></div>
        <OrderTotal price={price} showConvienience={true} />
        <PaymentSummary
          dateString={dateString}
          cardName={paymentMethod === "CARD" ? scheme : paymentMethod}
          last4={last4}
        />
        <div className="separator"></div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <button className="save-booking-btn" onClick={getImage}>
            <Download /> <div className="add-cal-btn-txt">Save booking</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingStatus;

const t = {
  success: true,
  status: "OK",
  data: {
    transfer: {
      id: 114005,
      from: "69efbfbd-efbf-bd4c-efbf-bdefbfbd11ef",
      sourceWallet: {
        id: "03461535-efbf-bdef-bfbd-11efbfbd5602",
        currency: "AED",
        blockedBalance: 0,
        balance: 5984333,
        category: "PRIMARY",
        cashoutStatus: "AVAILABLE",
        createdAt: 1654103162000,
        updatedAt: 1687429146000,
      },
      fromCurrency: "AED",
      fromAmount: 10300,
      to: "a1539b8f-49bb-4d74-9326-441966cfba43",
      destinationWallet: {
        id: "72859076-7572-4d05-b310-8ff29267c627",
        currency: "AED",
        blockedBalance: 15148749,
        balance: 15682330,
        category: "PRIMARY",
        cashoutStatus: "AVAILABLE",
        createdAt: 1681812692000,
        updatedAt: 1687429146000,
      },
      toCurrency: "AED",
      toAmount: 10300,
      type: "GUEST_PAYMENT",
      status: "SUCCESS",
      description: "e6672893-4d26-4fb9-8d9b-f6c8d2331fad",
      referenceId: "102306221140059696",
      externalTransfer: {
        id: 3990,
        amount: 10300,
        externalReferenceId: "368496265775",
        type: "GUEST_PAYMENT",
        status: "SUCCESS",
        paymentPlatform: "CHECKOUT",
        paymentMethod: "APPLE_PAY",
        paymentMethodMeta: {
          applePay: {
            last4: "5093",
            scheme: "Mastercard",
            type: "",
            bank: "",
          },
        },
        description: "e6672893-4d26-4fb9-8d9b-f6c8d2331fad",
      },
      createdAt: 1687429146000,
      updatedAt: 1687429146000,
    },
    transactionCategory: "MONETARY",
    transactionType: "CREDIT",
    status: "SUCCESS",
    amountOnBill: 10300,
    amountReceived: 10300,
    amountDiscounted: 0,
    serviceFee: 0,
    serviceTax: 0,
    guestTransaction: {
      listPrice: 10300,
      billAmount: 10300,
      amountBlockedOnRevenue: 103,
      discountedAmount: 0,
      discountPercentage: 0,
      merchantBillAmount: 10192,
      businessTip: 0,
      convenienceFeePercentage: 0,
      convenienceFee: 0,
      mdrOnBusinessBill: 103,
      mdrOnTipBill: 0,
      vatOnConvenienceFee: 0,
      vatOnBusinessBill: 5,
      vatOnTip: 0,
    },
    tipAmount: 0,
  },
};
