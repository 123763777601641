export const Images = {
  Logo: require("src/assets/images/logo.png"),
  Flag: require("src/assets/images/flag.png"),
  StoreLogo: require("src/assets/images/store-logo.png"),
  StoreImage: require("src/assets/images/store-image.png"),
  StoreBG: require("src/assets/images/storebg.png"),
  Stylist: require("src/assets/images/stylist.png"),
  AppleIcon: require("src/assets/images/apple.svg"),
  AppleBlackIcon: require("src/assets/images/apple-black.svg"),
  Notes: require("src/assets/images/notes.svg"),
  ErrorMessage: require("src/assets/images/error_message.svg"),
  NotAvailable: require("src/assets/images/not_available.png"),
  GoogleIcon: require("src/assets/images/google-color-icon.svg"),
  YahooIcon: require("src/assets/images/Yahoo.svg"),
  Outlook: require("src/assets/images/outlook.svg"),
  CalendarIcon: require("src/assets/images/calendar.svg"),
  ClockIcon: require("src/assets/images/clock.svg"),
  ClockChip: require("src/assets/images/clockChip.svg"),
  Payment: require("src/assets/images/payment.svg"),
};
