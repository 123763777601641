import React, { useCallback, useState } from "react";
import { Images } from "src/assets/images";
import Calendar from "src/components/common/Calendar";
import TimeSlots from "src/components/booking/time-slots";
import "./styles.css";
import moment from "moment";
import { useEffect } from "react";
import { helperService } from "src/services/helperService";
import { AuthService } from "src/services/api/authService";
import { useDispatch, useSelector } from "react-redux";
import Loader from "src/components/loader";
import AuthAction from "src/stores/auth/actions";
console.log("AuthAction", AuthAction);
const BookingSlots = ({ history, location }) => {
  const {
    data: {
      personalInfo: { id, first_name, last_name, price } = {},
      jobInfo: { job_name } = {},
      profilePic = "",
      specificDate = Date.now(),
    },
    sessionData: { sessionId, name, description, duration },
  } = location?.state || {};

  const [selectedDate, setSelectedDate] = useState(new Date(specificDate));
  const [formattedDate, setFormattedDate] = useState(
    moment(new Date()).format("dddd, DD MMMM")
  );
  const [guestId, _] = useState(helperService.getItem("guestId")); //useSelector((state) => state.auth.guestId)
  const [availableSlotsData, setAvailableSlotsData] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotsDataLoading, setSlotsDataLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const disptach = useDispatch();

  const handleGetAllAvailableData = useCallback(async () => {
    if (guestId) {
      setSlotsDataLoading(true);
      const guestsData = [
        {
          item: {
            id: sessionId,
            itemName: name,
            itemDescription: description,
          },
          therapist: {
            id,
            name: first_name + " " + last_name,
            designation: job_name,
          },
        },
      ];
      const requestBody = {
        date: selectedDate,
        guests: [{ id: guestId, items: guestsData }],
      };
      const response = await AuthService.getAvailableSlots(requestBody);
      if (response && response.success) {
        setAvailableSlotsData(response.data?.[id]?.availableSlots || []);
        setBookingId(response.data?.[id]?.booking);
      }
      setSlotsDataLoading(false);
    }
  }, [guestId, sessionId, id, selectedDate]);

  useEffect(() => {
    handleGetAllAvailableData();
  }, [handleGetAllAvailableData]);

  const storeData = useSelector((state) => state.auth.storeData);
  const businessName = storeData?.brand?.name;
  const businessPlace = storeData?.name;
  const logoSrc = storeData.profilePictureUrl || Images.Logo;

  const renderHeader = () => {
    return (
      <div className="header-container">
        <img
          src={
            storeData?.businessStoreCardDetails?.heroBanner || Images.StoreBG
          }
          alt="store bg"
          className="header-img"
        />
        <div className="header-logo-div">
          <img src={logoSrc} alt="store logo" className="header-logo" />
          <p className="name">{businessName}</p>
          <p className="business">{businessPlace}</p>
        </div>
      </div>
    );
  };

  const renderItem = () => {
    return (
      <div className="stylelist-wrapper">
        <div key={id} className="stylelist-parent-container">
          <div className="stylist-container">
            <div className="stylist-left-container">
              {profilePic.length > 0 ? (
                <img src={profilePic} className="stylist-img" />
              ) : (
                <div className="stylist-img-bg">
                  <div className="stylist-img-bg-text">
                    {first_name[0]}
                    {last_name[0]}
                  </div>
                </div>
              )}
              <div className="stylist-details">
                <div className="stylist-name">
                  {first_name} {last_name}
                </div>
                <div>{job_name}</div>
              </div>
            </div>
            <div className="stylist-amount">AED {price}</div>
          </div>
        </div>
        <div className="separator"></div>
      </div>
    );
  };

  const handleBookingConfirm = useCallback(async () => {
    setShowLoading(true);
    const response = await AuthService.confirmBooking({
      bookingId,
      slot: selectedSlot,
      accountId: storeData.account?.id,
    });
    if (response && response.success) {
      const prePaymentData = {
        data: response.data,
        stylistData: {
          personalInfo: { id, first_name, last_name, price },
          jobInfo: { job_name },
          profilePic,
        },
        sessionData: { name, duration, description },
      };
      helperService.setItem("prePaymentData", prePaymentData);
      disptach(AuthAction.OpenBookingExpireWarningModal(""));
      history.push({ pathname: "/confirm-payment", state: prePaymentData });
    } else {
      document.getElementById("confirm__button").classList.remove("touched");
    }
    setShowLoading(false);
  }, [
    bookingId,
    description,
    duration,
    first_name,
    history,
    id,
    job_name,
    last_name,
    name,
    price,
    selectedSlot,
    profilePic,
  ]);

  useEffect(() => {
    setFormattedDate(moment(selectedDate).format("dddd, DD MMMM"));
  }, [selectedDate]);

  const handleSlotChange = (slot) => {
    helperService.setItem("selectedSlot", slot);
    setSelectedSlot(slot);
  };

  return (
    <div className="store-cat-container">
      {renderHeader()}
      <div className="category-heading">Select your time</div>
      <div className="booking-heading">
        <div className="booking-heading-text">{name}</div>
        <div className="booking-heading-text">{duration} min</div>
      </div>
      <div className="booking-subheading">{description}</div>
      <div className="separator width-90"></div>
      {renderItem()}
      <div className="slot-wrapper">
        <Calendar
          onChange={(date) => {
            date.setHours(date.getHours() + 5);
            date.setMinutes(date.getMinutes() + 30);
            setSelectedDate(date);
          }}
          selectedDate={selectedDate}
        />
        <div className="separator"></div>
        <TimeSlots
          formattedDate={formattedDate}
          availableSlotsData={availableSlotsData}
          changeSelecteeSlot={handleSlotChange}
          selectedSlot={selectedSlot}
          slotsDataLoading={slotsDataLoading}
        />
      </div>
      <button
        id="confirm__button"
        className={"confirm__button " + (!selectedSlot ? "touched" : "")}
        onClick={handleBookingConfirm}
        onMouseDown={() =>
          document.getElementById("confirm__button").classList.add("touched")
        }
        disabled={!selectedSlot}
      >
        {showLoading ? <Loader /> : "Select your slot"}
      </button>
    </div>
  );
};
export default BookingSlots;
