import React from "react";
import { ReactComponent as MasterCardLogo } from "src/assets/images/mastercard.svg";
import { ReactComponent as Visa } from "src/assets/images/visa.svg";
import { ReactComponent as AmexLogo } from "src/assets/images/amex.svg";
import { ReactComponent as MaestroLogo } from "src/assets/images/maestro.svg";
import { ReactComponent as ApplePay } from "src/assets/images/applepay.svg";
import { ReactComponent as BalanceLogo } from "src/assets/images/balanceLogo.svg";
import Styles from "src/containers/merchant-payment-status/styles.module.css";
const PaymentSummary = ({ dateString, cardName, last4 }) => {
  console.log(cardName);
  const getIcon = () => {
    switch (cardName?.toLowerCase()) {
      case "visa":
        return <Visa style={{ marginRight: 10 }} />;
      case "mastercard":
        return <MasterCardLogo style={{ marginRight: 10 }} />;
      case "maestro":
        return <MaestroLogo style={{ marginRight: 10 }} />;
      case "amex":
        return <AmexLogo style={{ marginRight: 10 }} />;
      case "apple_pay":
        return <ApplePay style={{ marginRight: 10 }} />;
      case "pay_by_balance":
        return <BalanceLogo style={{ marginRight: 10 }} />;
      default:
        return <Visa style={{ marginRight: 10 }} />;
    }
  };
  console.log("last4", last4, cardName, getIcon());

  return (
    <div className={Styles["payment-summary-container"]}>
      <div className={Styles["page-title-text"]}>Payment summary</div>
      <div className={Styles["payment-summary-type"]}>
        {getIcon()}{" "}
        {cardName === "PAY_BY_BALANCE"
          ? "Balance"
          : cardName === "APPLE_PAY"
          ? "Apple Pay"
          : cardName}{" "}
        {last4}
      </div>
      <div className={Styles["payment-date"]}>{dateString}</div>
    </div>
  );
};

export default PaymentSummary;
