import { MerchantService } from "src/services/api/merchantService";
import { MerchantTypes } from "src/stores/merchant/actions";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import MerchantActions from "src/stores/merchant/actions";
import _ from "lodash";
import { helperService } from "src/services/helperService";

export function* getMerchantPaymentDetails({ payload, type }) {
  const { businessUserId, paymentId, token, history } = payload;

  console.log(businessUserId, paymentId, token);
  try {
    yield put(MerchantActions.getMerchantPaymentDetailsLoading());
    const response = yield call(MerchantService.getMerchantDetails, {
      businessUserId,
      paymentId,
      token,
    });

    if (response?.data) {
      yield put(
        MerchantActions.getMerchantPaymentDetailsSuccess(response?.data)
      );
    } else {
      yield put(MerchantActions.getMerchantPaymentDetailsFailure());
    }
  } catch (err) {
    yield put(MerchantActions.getMerchantPaymentDetailsFailure());
  }
}

// authorization

export function* paymentAuthorization({
  payload = {
    clientId: "00f6f03c-6ef5-407c-b06d-334db080b6f4",
    clientType: "BUSINESS",
    clientChannel: "QR",
  },
  type,
}) {
  const { clientId, clientType, clientChannel } = payload;
  console.log(payload);
  try {
    yield put(MerchantActions.paymentAuthorizationLoading());
    const response = yield call(MerchantService.paymentAuthorization, {
      clientId,
      clientType,
      clientChannel,
    });
    if (response?.data) {
      yield put(MerchantActions.paymentAuthorizationSuccess(response?.data));
    }
  } catch (err) {
    console.log(err);
    yield put(MerchantActions.paymentAuthorizationFailure());
  }
}

// initiate

export function* paymentInitiate({ payload, type }) {
  console.log(payload, "IN PAYMENT INITIATE");
  delete payload.paymentId;
  const failureCB = payload?.setPaymentFailureError;
  const showLoader = payload?.showLoader;
  const applePayCallBack = payload?.applePayCallBack;
  const successCallBack = payload?.successCallBack;

  if (payload.setPaymentFailureError) {
    delete payload.setPaymentFailureError;
  }
  if (payload.showLoader) {
    delete payload.showLoader;
  }
  if (payload.applePayCallBack) {
    delete payload.applePayCallBack;
  }
  if (payload.successCallBack) {
    delete payload.successCallBack;
  }
  try {
    const authPayload = {
      clientId: helperService.getItem("businessUserId"),
      clientType: "BUSINESS",
      clientChannel: "QR",
    };
    const Authresponse = yield call(
      MerchantService.paymentAuthorization,
      authPayload
    );
    if (Authresponse?.data) {
      yield put(
        MerchantActions.paymentAuthorizationSuccess(Authresponse?.data)
      );
    }

    yield put(MerchantActions.paymentInitiateLoading());
    const authToken = yield select(
      (state) => state.merchant?.paymentAuthorizationSuccess?.accessToken
    );

    const data = yield select(
      (state) => state.merchant?.getMerchantPaymentDetailsSuccess
    );
    delete payload?.authToken;
    console.log(authToken, "insaga");
    const response = yield call(MerchantService.paymentInitiate, {
      payload: { ...payload, amount: data?.merchantRequestDTO?.amount },
      authToken,
    });
    console.log(response);
    if (response?.data) {
      helperService.setItem("requestId", response?.data.requestId);
      if (payload.transferType === "APPLE_PAY") {
        applePayCallBack(
          response?.data?.checkoutResponse?.redirectUrl,
          successCallBack
        );
        //   yield put(AuthActions.CloseBookingLoadingModal(""));
        showLoader(false);
      } else {
        console.log(response);
        yield put(MerchantActions.paymentInitiateSuccess(response?.data));
        showLoader(false);
        return { success: true, data: response };
      }
    }
  } catch (err) {
    console.log(err);
    failureCB && failureCB(true);
    showLoader(false);
    yield put(MerchantActions.paymentInitiateFailure());
  }
}

// process

export function* paymentProcess({ payload, type }) {
  console.log(payload);
  const { history } = payload;
  const callBack = payload.callBack;
  delete payload.history;
  try {
    yield put(MerchantActions.paymentProcessLoading());
    const response = yield call(MerchantService.paymentProcess, payload);

    if (response?.data) {
      yield put(MerchantActions.paymentProcessSuccess(response?.data));
      helperService.setItem("paymentProcess", response?.data);
      console.log(history);
      callBack(true);
      history.push("/merchant-payment-status");
    }
  } catch (err) {
    console.log(err);
    yield put(MerchantActions.paymentProcessFailure());
  }
}

// payment details

export function* getPaymentDetails({ payload, type }) {
  console.log(payload);
  try {
    yield put(MerchantActions.getPaymentDetailsLoading());
    const response = yield call(MerchantService.getPaymentDetails, payload);

    if (response?.data) {
      yield put(MerchantActions.getPaymentDetailsSuccess(response?.data));

      helperService.setItem("getPaymentDetails", response?.data);
      localStorage.removeItem("merchantAccessToken");
    }
  } catch (err) {
    console.log(err);
    yield put(MerchantActions.getPaymentDetailsFailure());
  }
}

export default function* MerchantSaga() {
  yield all([
    takeEvery(
      MerchantTypes.GET_MERCHANT_PAYMENT_DETAILS,
      getMerchantPaymentDetails
    ),
    takeEvery(MerchantTypes.PAYMENT_AUTHORIZATION, paymentAuthorization),
    takeEvery(MerchantTypes.PAYMENT_INITIATE, paymentInitiate),
    takeEvery(MerchantTypes.PAYMENT_PROCESS, paymentProcess),
    takeEvery(MerchantTypes.GET_PAYMENT_DETAILS, getPaymentDetails),
  ]);
}
