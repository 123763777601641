import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router";
import { Images } from "src/assets/images";
import { AuthService } from "src/services/api/authService";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import AuthActions from "src/stores/auth/actions";
import ContentLoader from "react-content-loader";
import { helperService } from "src/services/helperService";
import ErrorMessage from "src/components/ErrorMessage";

const StoreCategory = (props) => {
  // const cancelBooking = useCallback(async () => {
  //   if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
  //     return;
  //   }
  //   const prePaymentData = helperService.getItem("prePaymentData");
  //   const selectedSlot = helperService.getItem("selectedSlot");
  //   if (prePaymentData?.data?.id && selectedSlot) {
  //     await AuthService.cancelBooking({
  //       invoiceId: prePaymentData.data.id,
  //       slot: selectedSlot,
  //     });
  //     window.localStorage.clear();
  //     window.location.reload();
  //   }
  // }, []);

  // useEffect(() => {
  //   cancelBooking();
  // }, [cancelBooking]);

  const [storeData, setStoreData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showServerError, setShowServerError] = useState(false);

  const handleGetAllCategories = useCallback(async () => {
    setLoading(true);
    const response = await AuthService.categoriesList();
    if (response && response.success) {
      setStoreData(response.data);
    } else {
      setShowServerError(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    handleGetAllCategories();
  }, [handleGetAllCategories]);

  const data = useSelector((state) => state.auth.storeData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AuthActions.fetchStoreData());
  }, [dispatch]);

  const businessName = data?.brand?.name;
  const businessPlace = data?.name;
  const logoSrc = data.profilePictureUrl || Images.Logo;

  const renderHeader = () => {
    return (
      <div className="header-container">
        <img
          src={data?.businessStoreCardDetails?.heroBanner || Images.StoreBG}
          alt="store bg"
          className="header-img"
        />
        <div className="header-logo-div">
          <img src={logoSrc} alt="store logo" className="header-logo" />
          <div className="name">{businessName}</div>
          <div className="business">{businessPlace}</div>
        </div>
      </div>
    );
  };

  console.log(helperService.getItem("touchedElement"));
  const renderItem = (item, index) => {
    return (
      <div
        style={index === 0 ? { marginTop: 30 } : {}}
        id={item.name}
        key={index}
        className={"item-container"}
        onClick={() => {
          document.getElementById(item.name).classList.add("touched");
          setTimeout(() => {
            props.history.push({ pathname: "/session", state: { data: item } });
            document.getElementById(item.name).classList.remove("touched");
          }, 200);
        }}
      >
        <div className="item-text">{item.name}</div>
        <div className="separator"></div>
      </div>
    );
  };

  if (showServerError) {
    return <ErrorMessage />;
  }

  return (
    <div className="store-cat-container">
      {renderHeader()}
      <div className="category-heading">Select your category</div>
      {storeData.map((item, index) => {
        return renderItem(item, index);
      })}
      {loading && (
        <div>
          <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
            {/* Only SVG shapes */}
            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
          </ContentLoader>
          <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
            {/* Only SVG shapes */}
            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
          </ContentLoader>
          <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
            {/* Only SVG shapes */}
            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
          </ContentLoader>
          <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
            {/* Only SVG shapes */}
            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
          </ContentLoader>
        </div>
      )}
    </div>
  );
};
export default withRouter(StoreCategory);
