import React from "react";
import "./styles.css";

const OrderTotal = ({ price, showConvienience }) => {
  const {
    billAmount,
    listPrice,
    taxAmount,
    discountedAmount,
    convenienceFee,
    discountPercentage,
    convenienceFeePercentage,
  } = price;
  console.log(convenienceFee, "convenienceFee");
  console.log(price, showConvienience, billAmount, convenienceFee);
  return (
    <div className="order-total-container">
      <div className="total-title-container">
        <div className="total-title">Total</div>
        <div className="total-amount">
          AED{" "}
          {showConvienience
            ? (Number(billAmount) / 100).toFixed(2)
            : ((Number(billAmount) - Number(convenienceFee)) / 100).toFixed(2)}
        </div>
      </div>
      <div>
        <div className="total-split-container">
          <div className="total-parameter">List price</div>
          <div className="total-parameter">
            AED {(listPrice / 100).toFixed(2)}
          </div>
        </div>
        {/* <div className='total-split-container'>
                    <div className='total-parameter'>Tax (10%)</div>
                    <div className='total-parameter'>AED {taxAmount}</div>
                </div> */}
        <div className="total-split-container">
          <div className="total-parameter">
            Discount ({discountPercentage / 100 + "%"})
          </div>
          <div className="total-parameter">
            (AED {(discountedAmount / 100).toFixed(2)})
          </div>
        </div>
        {showConvienience && (
          <div className="total-split-container">
            <div className="total-parameter">
              Convenience fee ({convenienceFeePercentage / 100 + "%"})
            </div>
            <div className="total-parameter">
              AED {(convenienceFee / 100).toFixed(2)}
            </div>
          </div>
        )}
      </div>
      <div className="order-total-text">Payments are not refundable</div>
      <div className="separator"></div>
    </div>
  );
};
export default OrderTotal;
