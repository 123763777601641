// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-warning-heading-txt {
  font-family: "Light";
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  padding-top: 12%;
  padding-inline: 10px;
}

.booking-warning-sub-heading-txt {
  font-family: "Light";
  font-size: 18px;
  line-height: 24px;
  padding-top: 8%;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/BookingExpireWarningModal/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".booking-warning-heading-txt {\n  font-family: \"Light\";\n  font-size: 32px;\n  line-height: 36px;\n  text-align: center;\n  padding-top: 12%;\n  padding-inline: 10px;\n}\n\n.booking-warning-sub-heading-txt {\n  font-family: \"Light\";\n  font-size: 18px;\n  line-height: 24px;\n  padding-top: 8%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
