// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-slots-wrapper{
    width: 100%;
    margin-top:20px;
    display: flex;
    flex-direction: column;
}
.time-slot-date{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Regular';
}
.time-slot-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.time-slot{
    width: 75px;
    height:35px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: 'Light';
    font-size: 16px;
    color: #000;
    padding:5px;
    border:1px solid #ccc;
    border-radius: 10px;
    margin-top:15px;
    margin-bottom:0px;
    background-color: transparent;
    margin-left:12px
}
.time__slot-active{
    background-color: #000;
    color:#fff;
    font-family: 'Regular';
    font-weight: 400;
}

.no-slots-text{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-family: 'Light';
    font-size: 16px;
    line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/booking/time-slots/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,WAAW;IACX,WAAW;IACX,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;IAC7B;AACJ;AACA;IACI,sBAAsB;IACtB,UAAU;IACV,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".time-slots-wrapper{\n    width: 100%;\n    margin-top:20px;\n    display: flex;\n    flex-direction: column;\n}\n.time-slot-date{\n    margin-bottom: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-family: 'Regular';\n}\n.time-slot-container{\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n}\n.time-slot{\n    width: 75px;\n    height:35px;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    font-family: 'Light';\n    font-size: 16px;\n    color: #000;\n    padding:5px;\n    border:1px solid #ccc;\n    border-radius: 10px;\n    margin-top:15px;\n    margin-bottom:0px;\n    background-color: transparent;\n    margin-left:12px\n}\n.time__slot-active{\n    background-color: #000;\n    color:#fff;\n    font-family: 'Regular';\n    font-weight: 400;\n}\n\n.no-slots-text{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100px;\n    font-family: 'Light';\n    font-size: 16px;\n    line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
