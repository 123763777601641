import "./styles.css";
import React from "react";
import { Images } from "src/assets/images";
import useDetectKeyboardOpen from "src/hooks/useDetectKeyboardOpen";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AuthActions from "src/stores/auth/actions";
import { ReactComponent as ErrorIcon } from "src/assets/images/errorIcon.svg";

const Profile = (props) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const dispatch = useDispatch();
  const { mobile_phone = "", country_code = "" } = props.location?.state || {};

  const storeData = useSelector((state) => state.auth.storeData);

  const businessName = storeData?.brand?.name;
  const businessPlace = storeData?.name;
  const logoSrc = storeData.profilePictureUrl || Images.Logo;

  const isKeyboardOpen = useDetectKeyboardOpen();

  const [focusLastName, setFocusLastName] = React.useState(false);

  const handlefirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handlelastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleNextBtn = () => {
    document.getElementById("profile__button").classList.add("touched");
    dispatch(
      AuthActions.updateUser({
        personal_info: {
          first_name: firstName,
          last_name: lastName,
          mobile_phone: {
            country_code,
            number: mobile_phone,
          },
        },
        history: props.history,
      })
    );
  };

  return (
    <div className="profile-main">
      <div className="profile-container">
        <img src={logoSrc} alt="Logo" className="logo" />
        <p className="profile-name">{businessName}</p>
        <p className="profile-business">{businessPlace}</p>
        <p className="profile-heading">Please enter your name</p>

        <input
          className="profile-input-div"
          placeholder="First name"
          value={firstName}
          onChange={handlefirstNameChange}
          autoFocus={true}
          onBlur={() => setFocusLastName(true)}
        />
        <input
          className="profile-input-div"
          placeholder="Last name"
          value={lastName}
          onChange={handlelastNameChange}
          autoFocus={focusLastName}
          onBlur={() => {
            setFocusLastName(false);
          }}
        />
      </div>
      {firstName.length > 0 &&
      lastName.length > 0 &&
      (firstName.length < 2 || lastName.length < 2) ? (
        <div className="invalid-profile-container">
          <ErrorIcon className="error-icon" />
          <div className="invalid-name-text">
            First name and last name must contain a minimum of 2 characters.
          </div>
        </div>
      ) : (
        <div
          className={
            !isKeyboardOpen
              ? "profile-bottom-div profile-bottom-div-absolute"
              : "profile-bottom-div profile-bottom-div-absolute1"
          }
        >
          <button
            className="nextBtn"
            id="profile__button"
            disabled={!firstName || !lastName}
            onClick={handleNextBtn}
          >
            {" "}
            <p className="nextBtnTxt">Next</p>{" "}
          </button>
        </div>
      )}
    </div>
  );
};

Profile.propTypes = {
  // logoSrc: PropTypes.string.isRequired,
  // businessName: PropTypes.string.isRequired,
  // businessPlace: PropTypes.string.isRequired,
};

export default withRouter(Profile);
