import React from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Images } from 'src/assets/images'
import ErrorMessage from 'src/components/ErrorMessage'
import { AuthService } from 'src/services/api/authService'
import { helperService } from 'src/services/helperService'


const StoreSession = (props) => {
    const {data = {}} = props?.location?.state || {}

    const [storeSessionData, setStoreSessionData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showServerError, setShowServerError] = useState(false)

    const handleGetAllSessionData = useCallback(async() => {
        setLoading(true)
        const response = await AuthService.sessionList({categoryId: data?.id, page: 1})
        console.log(response)
        if(response.success){
            const sesstionData = response?.data?.services
            sesstionData.sort((a,b) => a.catalog_info.display_order - b.catalog_info.display_order)
            setStoreSessionData(response?.data?.services)
        } else {
            setShowServerError(true)
        }
        setLoading(false)
    },[props?.data?.id])

    useEffect(()=> {
        handleGetAllSessionData()
    }, [handleGetAllSessionData])

    const storeData = useSelector((state) => state.auth.storeData)
    const businessName = storeData?.brand?.name;
    const businessPlace = storeData?.name;
    const logoSrc = storeData.profilePictureUrl || Images.Logo;
    
    const renderHeader = () => {
        return (
            <div className='header-container'>
                <img src={storeData?.businessStoreCardDetails?.heroBanner || Images.StoreBG} alt="store bg" className='header-img' />
                <div className='header-logo-div'>
                    <img src={logoSrc} alt="store logo" className="header-logo" />
                    <p className='name'>{businessName}</p>
                    <p className='business'>{businessPlace}</p>
                </div>
            </div>
        )
    }

    const renderItem = ({ id, name, description, duration }) => {
        return (
            <div id={id} key={id} className={"category-details"} onClick={()=>{
                document.getElementById(id).classList.add('touched')
                setTimeout(()=>{
                    props.history.push({pathname: "/booking", state: {categoryId: data?.id, sessionId: id, name, description, duration}})
                    document.getElementById(id).classList.remove('touched')
                },200)
            }}
            >
                <p className='duration-title'>{name}</p>
                <p className='session-item-description'>{description}</p>
                <div className='duration-container'>
                    <div className='duration-title'>Duration</div>
                    <div className='duration-title'>{duration} min</div>
                </div>
                <div className='separator'></div>
            </div>
        )
    }



    if(showServerError){
        return <ErrorMessage/>
    }

    return (
        <div className='store-cat-container'>
            {renderHeader()}
            <div className='category-heading'>Select your session</div>
            <div className='title-container'>
                <div className='item-text'>{data.name}</div>
                <div className='separator'></div>
            </div>
            {storeSessionData.map((item, index) => {
                return (
                    renderItem(item, index)
                )
            })}
            {loading &&
                <div>
                    <ContentLoader viewBox="60 0 380 70" foregroundColor={'#999'}>
                        {/* Only SVG shapes */}
                        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                    </ContentLoader>
                    <ContentLoader viewBox="60 0 380 70" foregroundColor={'#999'}>
                        {/* Only SVG shapes */}
                        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                    </ContentLoader>
                    <ContentLoader viewBox="60 0 380 70" foregroundColor={'#999'}>
                        {/* Only SVG shapes */}
                        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                    </ContentLoader>
                    <ContentLoader viewBox="60 0 380 70" foregroundColor={'#999'}>
                        {/* Only SVG shapes */}
                        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                    </ContentLoader>
                </div>
            }
        </div>
    )
}
export default withRouter(StoreSession)