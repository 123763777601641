import { Box, Modal } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as Payment } from "src/assets/images/payment.svg";
import React from "react";
import { useSelector } from "react-redux";

const BookingLoaderModel = () => {
  const isBookingLoadingModalOpen = useSelector(
    (state) => state.auth.isBookingLoadingModalOpen
  );
  const paymentProcessLoading = useSelector(
    (state) => state.merchant.paymentProcessLoading
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    display: "flex",
    p: 4,
    paddingTop: 6,
    paddingBottom: 6,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    color: "black",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <div>
      <Modal
        open={isBookingLoadingModalOpen || paymentProcessLoading}
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
        }}
      >
        <Box sx={style}>
          <Payment />
          <div
            className="booking-warning-heading-txt"
            style={{ paddingTop: "10%", color: "white" }}
          >
            Please wait while we complete your payment
          </div>
          <Box sx={{ width: "100%", marginTop: "14%" }}>
            <LinearProgress
              sx={{
                "&.MuiLinearProgress-root": {
                  height: "12px",
                  borderRadius: "16px",
                },
                "&.MuiLinearProgress-colorPrimary": {
                  background: "#E3E7EE",
                },
              }}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BookingLoaderModel;
