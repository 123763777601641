// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 60px;
}

.error-heading-txt{
    font-family: 'Light';
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    padding-top: 42px;
    padding-inline: 10px;
}

.error-sub-heading-txt{
    font-family: 'Light';
    font-size: 18px;
    line-height: 24px;
    padding-top: 32px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorMessage/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".error-container{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding-inline: 60px;\n}\n\n.error-heading-txt{\n    font-family: 'Light';\n    font-size: 32px;\n    line-height: 36px;\n    text-align: center;\n    padding-top: 42px;\n    padding-inline: 10px;\n}\n\n.error-sub-heading-txt{\n    font-family: 'Light';\n    font-size: 18px;\n    line-height: 24px;\n    padding-top: 32px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
